import React from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

import { BsBoxSeam } from "react-icons/bs";

const VendaBox = () => {
  const cart = useSelector((state) => state.cart);
  const navigate = useNavigate();
  return (
    <div>
      {cart.cartItems.length === 0 ? (
        <div className=" flex items-center justify-center h-screen ">
          <div className="text-secondary-200  mx-auto text-center -mt-72">
            <BsBoxSeam className="inline-block mb-4" size={50} />
            <p className="text-center text-2xl">Your Venda Box is empty</p>
            <p className="text-center text-normal">Continue shopping</p>
            <div className=" flex items-center mt-5 justify-center">
              <button
                onClick={() => navigate("/business/shop/products")}
                className="bg-primary-200 text-white text-sm py-2 px-8 rounded-md"
              >
                Go to Shop
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className=" max-w-5xl mx-auto flex justify-between">
            <div className="flex items-center">
              <div className="h-8 w-8 mr-3 rounded-full bg-primary-100  flex items-center justify-center">
                1
              </div>
              <p className=" text-sm">Review Venda Box</p>
            </div>
            <div className="flex items-center">
              <div className="h-8 w-8 mr-3 rounded-full bg-primary-100  flex items-center justify-center">
                2
              </div>
              <p className=" text-sm">Payment & Delivery</p>
            </div>
            <div className="flex items-center">
              <div className="h-8 w-8 mr-3 rounded-full bg-primary-100  flex items-center justify-center">
                3
              </div>
              <p className=" text-sm">Confirmation</p>
            </div>
          </div>
          <div className="p-6 mt-8 bg-white shadow rounded-lg">
            <Outlet />
          </div>
        </>
      )}
    </div>
  );
};

export default VendaBox;
