import React from "react";

import { Twitter, Instagram, Linkedin, Facebook } from "react-feather";
import { PopupButton } from "@typeform/embed-react";

import "./index.css";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <div className="flex flex-col md:flex-row justify-between">
        {/* About */}
        <div className="basis-1/4 mx-5">
          <h3 className="footer-header">Venda</h3>
          <p className="font-light mb-10 md:mb-0">
            Powering Africa’s FMCG retail sector with technology, starting from
            Ghana 🇬🇭
          </p>
        </div>

        {/* company */}
        <div className="basis-1/4 mx-5">
          <h3 className="footer-header">Company</h3>
          <ul className="footer-links">
            {/* <li>How it ww</li> */}
            <NavLink to="/terms-of-service">
              <li>Terms of Service</li>
            </NavLink>
            <NavLink to="/privacy-policy">
              <li>Privacy Policy</li>
            </NavLink>

            {/* <li>Partners</li>
            <li>Careers</li> */}
          </ul>
        </div>

        {/* Resources */}
        <div className="basis-1/4 mx-5">
          <h3 className="footer-header">Resources</h3>
          <ul className="footer-links">
            {/* <li>Use Cases</li>
            <li>Product Features</li> */}
            <li>
              {" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://shopvenda.medium.com/"
              >
                Blog
              </a>
            </li>
          </ul>
        </div>

        {/* get in touch */}
        <div className="basis-1/4 mx-5">
          <h3 className="footer-header">
            {" "}
            <a href="mailto:hello@vendasupply.com&subject=Hello Venda!&body=I'd like to know about...">
              Get In Touch
            </a>
          </h3>
          <ul className="footer-links">
            <li>
              {" "}
              <a href="mailto:hello@vendasupply.com&subject=Hello Venda!&body=I'd like to know about...">
                hello@vendasupply.com
              </a>
            </li>
            <li>
              {" "}
              <a href="tel:233599629666">Call +233 599 629 666</a>
            </li>
            <li>
              {" "}
              <PopupButton id="E0WtokZ3">Join Newsletter</PopupButton>
            </li>
            <li>
              {" "}
              <a href="mailto:hello@vendasupply.com&subject=RequestingDemo!&body=I'd like to request a demo">
                Request a Demo
              </a>
            </li>
          </ul>
        </div>
      </div>

      <hr className="my-10 mx-10 text-center" />

      <div className="mx-5 text-sm flex mt-5 pb-3 flex-row justify-between">
        <p>©Venda {new Date().getFullYear()}</p>
        <div className="flex flex-row">
          <a
            className="mr-2"
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/vendahq"
          >
            <Twitter className="w-4" />
          </a>
          <a
            className="mr-2"
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/vendaghana"
          >
            <Facebook className="w-4" />
          </a>
          <a
            className="mr-2"
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/vendahq/"
          >
            <Instagram className="w-4" />
          </a>
          <a
            className="mr-2"
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/shopvenda"
          >
            <Linkedin className="w-4" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
