import React from "react";
import ProductCard from "./ProductCard";

import { useOutletContext } from "react-router-dom";

import { BsBoxSeam } from "react-icons/bs";

import { useSelector } from "react-redux";

import Loading from "../../../../components/Loading";

const AllProducts = () => {
  const { pending } = useSelector((state) => state.product);

  const [products, dataPerPage] = useOutletContext();

  return (
    <div className=" w-full min-h-screen mb-20 text-secondary-200">
      {pending ? (
        <div>
          <Loading
            mainText="Loading Products"
            text="Hang on. We are fetching all products"
          />
        </div>
      ) : (
        <>
          {pending && products.length === 0 && (
            <div className="h-screen -mt-56 w-full text-center mx-auto flex items-center justify-center">
              <div>
                <BsBoxSeam className="inline-block mb-4" size={50} />
                <p className="text-center text-3xl">
                  Ooops! No products found for this category
                </p>
                <p className="text-center mx-auto max-w-md text-normal">
                  Really, this is on us. We are working tirelessly to get you
                  the products you need at the lowest prices. Kindly check back
                  again in sometime
                </p>
              </div>
            </div>
          )}
          <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-y-36 mt-36">
            {products !== null &&
              products.map((product) => {
                return (
                  <div key={product.id}>
                    <ProductCard
                      name={product.name}
                      description={product.description.substring(0, 5)}
                      oldPrice="Ghc 20"
                      newPrice={`Ghc ${product.price}`}
                      image={product.imageUrl}
                      type="discount"
                      value="-20%"
                      id={product.id}
                    />
                  </div>
                );
              })}
          </div>
          {/* <div className="mt-5 ml-5">
            <p>
              {" "}
              Showing <span className="font-bold">{dataPerPage}</span> products
              per page
            </p>
          </div> */}
        </>
      )}
    </div>
  );
};

export default AllProducts;
