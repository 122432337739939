import React from "react";

import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";

import { clearCart } from "../../cart/cartSlice";

import { Check } from "react-feather";

function Success() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex justify-center items-center text-secondary-200">
        <div>
          <div className="mx-auto pt-6">
            <p className="h-20 w-20 mx-auto rounded-full flex items-center justify-center bg-primary-200">
              <Check className=" text-white h-20 w-14" />
            </p>
            <div className=" mt-6">
              <p className=" text-2xl font-bold mx-auto rounded-full flex items-center justify-center">
                Order Successful
              </p>
            </div>
          </div>
          <div className=" mx-auto text-sm mb-7 text-center">
            <p>
              Your order has been placed successfully and is being processed.
            </p>
            <p>A representative would contact you if there are any hitches</p>
          </div>
          <div className="flex justify-center mt-6 mb-16">
            <button
              onClick={() => {
                navigate(`/business/orders`);
                dispatch(clearCart());
              }}
              className=" bg-primary-200 py-2 px-6 rounded-md text-white text-xs"
            >
              See Pending Orders
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Success;
