import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import { getTotals } from "./pages/shop/business/cart/cartSlice";

// sentry
// import * as Sentry from "@sentry/react";

store.dispatch(getTotals());

// Sentry.init({
//   dsn: "https://c4c36e1b526d59170ce62d95ee9385a7@o4505768545222656.ingest.sentry.io/4505768549744640",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["localhost", "https://app.vendasupply.com"],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
