import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

import { useNavigate } from "react-router-dom";

const Modal = ({ isOpen, setIsOpen, email, setEmail, type = "sign" }) => {
  const navigate = useNavigate();
  function closeModal() {
    setIsOpen(false);
    setEmail("");
    navigate("/");
  }

  function openModal() {
    setIsOpen(true);
  }
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 backdrop-blur-sm bg-white/30" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white border border-purple-600 p-16 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-bold leading-6 text-gray-900"
                  >
                    {type === "sign"
                      ? "   Magic Link Sent 🚀"
                      : "   Business Account Created 🚀"}
                  </Dialog.Title>
                  <div className="mt-4">
                    {/* Will display the users email here so it will say link has been sent to example@mail.com */}
                    <p className="text-lg text-gray-500">
                      A magic link has been sent to{" "}
                      <span className="font-bold text-black">{email}</span>
                      <br />
                      Check your email for the link to sign into your Venda
                      account
                    </p>
                  </div>

                  {/* <div className="mt-4">
                    <button
                      type="button"
                      className="btn-primary-full w-full mt-4 disabled:opacity-75 shadow-xl disabled:cursor-not-allowed enabled:hover:bg-primary-400 transition-all"
                      onClick={closeModal}
                    >
                      Open Email app
                    </button>
                  </div> */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Modal;
