import React from "react";

import venda from "../assets/images/products/Venda.png";

function Image({ url }) {
  if (url.length > 12) {
    return (
      <img
        className="h-24 border border-gray-100 rounded-xl p-2 w-24 m-auto object-contain"
        src={url}
        alt="product"
      />
    );
  } else {
    return (
      <div
        className="border p-2 h-24 w-24 border-gray rounded-lg flex mx-auto text-cen
       items-center justify-center"
      >
        <img className="mx-auto object-contain" src={venda} alt="No data" />
      </div>
    );
  }
}

export default Image;
