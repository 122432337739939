import { ArrowRight } from "iconsax-react";
// import { CreditCard } from "react-feather";
import React from "react";
import * as FeatherIcons from "react-icons/fi";

import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AnalyticsCard = ({
  title,
  body,
  col,
  linkTo = "/business/shop/products",
  action,
  icon,
}) => {
  return (
    <div className=" bg-white shadow-sm rounded-[7px]">
      <div className="w-10/12 mx-auto py-6">
        <div
          className={classNames(
            col === 3 ? "bg-[#FFC3C3]" : "",
            col === 2 ? "bg-[#C3FFCD]" : "",
            col === 1 ? "bg-[#C3F1FF]" : "",
            col === 0 ? "bg-[#DEC3FF]" : "",
            "w-11 h-8 flex justify-center items-center rounded-md"
          )}
        >
          {/* <CreditCard className="h-7 w-5 mx-3" aria-hidden="true" /> */}
          {/* <div className="h-7 w-5 mx-3" icon={icon}>{icon}</div> */}
          <div className="h-7 w-5 mt-2">
            {React.createElement(FeatherIcons[`${icon}`])}
          </div>
        </div>
        <div className=" pt-4">
          <p className="text-xs text-neutral-100">{title}</p>
          <h2 className=" text-secondary-200 text-3xl font-bold">{body}</h2>
          <div className="w-full border"></div>
        </div>
        <Link className="m-auto" to={linkTo}>
          <div className=" text-sm font-light pt-4 flex justify-between">
            {action}
            <ArrowRight className="h-5 w-5 mx-3" aria-hidden="true" />{" "}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default AnalyticsCard;
