import { NavLink, useNavigate } from "react-router-dom";
import signup from "../../../assets/images/signup.png";
import AuthFooter from "../../../components/AuthFooter";
import Modal from "./Modal";

const ConfirmEmail = () => {
  return (
    <div>
      <Modal />
      <div className="mx-auto h-full">
        <div className="flex h-fit">
          <div className="w-full lg:w-1/2 m-auto px-8 ">
            <div className="lg:w-8/12 w-full mx-auto pt-16">
              <p className=" text-secondary-200 text-xl font-bold">Venda</p>
              <button className="btn-primary-full w-full mt-4 mb-7 disabled:opacity-75 shadow-xl disabled:cursor-not-allowed enabled:hover:bg-primary-400 transition-all">
                Open Email app
              </button>
            </div>
            <div className="mt-[60px]">
              <AuthFooter />
            </div>
          </div>
          <div className="hidden xl:inline w-1/2">
            <div className="h-full w-full">
              <img
                className="w-full h-full object-cover fixed"
                src={signup}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
