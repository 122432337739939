import React, { Fragment } from "react";
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { NavLink } from "react-router-dom";

import { ShoppingBagIcon } from "@heroicons/react/24/outline";

import Loading from "../../../../components/Loading";

// import Pagination from "../../../../components/Pagination";

import { getAllOrders } from "./orderSlice";

import { Tab } from "@headlessui/react";

import SingleOrders from "./SingleOrders";

const OrdersTable = () => {
  const dispatch = useDispatch();
  const { orders, pending } = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(getAllOrders());
  }, [dispatch]);

  const { cartTotalQuantity } = useSelector((state) => state.cart);

  return (
    <div>
      {/* Show current orders if any */}
      {Boolean(cartTotalQuantity) && (
        <div className="w-full border-secondary-200 justify-between  items-center border p-5 rounded-lg border-dashed flex flex-row gap-10">
          <div className="flex flex-row  gap-10">
            <div className="relative ">
              <ShoppingBagIcon className=" h-12 w-12" />
              <div class="inline-flex absolute animate-ping -top-1 -right-3 justify-center items-center w-5 h-5 text-[9px] font-bold text-white bg-primary-100 rounded-full"></div>
              <div class="inline-flex absolute -top-1 -right-3 justify-center pb-[0.6px] items-center w-5 h-5 text-[9px] font-bold text-white bg-primary-200 rounded-full">
                {cartTotalQuantity}
              </div>
            </div>

            <div>
              <h3 className="text-xl font-bold">Ongoing Order</h3>
              <p className=" text-sm">
                Seems you've got a few items in cart pending checkout.
              </p>
            </div>
          </div>
          <div>
            {" "}
            <NavLink to="/business/orders/venda-box">
              <button className=" bg-primary-200 hover:bg-primary-400 disabled:bg-gray-300 disabled:cursor-not-allowed py-2 px-6 rounded-md text-white text-xs">
                Go to Checkout
              </button>
            </NavLink>
          </div>{" "}
        </div>
      )}

      {/* Pending and completed tabs */}
      <Tab.Group>
        <div class="border-b border-gray-200 mt-2">
          <Tab.List class="flex -mb-px ">
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={selected ? "tab-active" : "tab-inactive"}>
                  <p>Pending Orders</p>
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={selected ? "tab-active" : "tab-inactive"}>
                  <p>Completed Orders</p>
                </button>
              )}
            </Tab>
          </Tab.List>
        </div>
        {pending ? (
          <div>
            <Loading
              mainText="Loading Orders"
              text="This may take a few seconds, please don't close this page."
            />
          </div>
        ) : (
          <Tab.Panels className="mt-7">
            {/* Pending Orders */}

            <Tab.Panel>
              <div className="mt-8 flex flex-col">
                <div className="  overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block  min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden ">
                      <table className="min-w-full text-secondary-200 divide-y divide-gray-300">
                        <thead className="bg-primary-100">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm  sm:pl-6"
                            >
                              Date
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm"
                            >
                              Order ID
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm"
                            >
                              Payment Method
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm"
                            >
                              Order Amount
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm"
                            >
                              Status
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <SingleOrders orders={orders} type="pending" />
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Tab.Panel>

            {/* Completed Orders */}
            <Tab.Panel>
              <div className="mt-8 flex flex-col">
                <div className="  overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block  min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden ">
                      <table className="min-w-full text-secondary-200 divide-y divide-gray-300">
                        <thead className="bg-primary-100">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm  sm:pl-6"
                            >
                              Date
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm"
                            >
                              Order ID
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm"
                            >
                              Payment Method
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm"
                            >
                              Order Amount
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm"
                            >
                              Status
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <SingleOrders orders={orders} type="completed" />
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        )}
      </Tab.Group>
    </div>
  );
};

export default OrdersTable;

//
// <div className="flex gap-4">
//   <button className="bg-primary-200 text-white text-sm py-2 px-6 rounded-md">
//     View Pending Orders
//   </button>

//   <button className="border border-secondary-200 text-secondary-200 text-sm py-1 px-6 rounded-md">
//     View All Orders
//   </button>
// </div>
