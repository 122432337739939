import React, { useEffect } from "react";
import Loading from "../../components/Loading";

function ForwardToAdmin() {
  console.log(window.location.pathname);

  useEffect(() => {
    //
    window.open(
      `https://management.vendasupply.com${window.location.pathname}`,
      "_self"
    );
  }, []);
  return (
    <div>
      <Loading />
    </div>
  );
}

export default ForwardToAdmin;
