import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./pages/auth/userSlice";
import productReducer from "./pages/shop/business/products/productSlice";
import orderReducer from "./pages/shop/business/orders/orderSlice";
import cartReducer from "./pages/shop/business/cart/cartSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    product: productReducer,
    order: orderReducer,
    cart: cartReducer,
  },
});