import React from "react";

import { NavLink } from "react-router-dom";
import { Tags } from "../../../../components/Tags";

import dayjs from "dayjs";
var localizedFormat = require("dayjs/plugin/localizedFormat");

function SingleOrders({ orders, type = "pending" }) {
  dayjs.extend(localizedFormat);

  let allOrders = orders.filter(
    (order) => order.orderStatus.toLowerCase() === type
  );

  if (allOrders.length === 0) {
    return <div className="w-full my-5">No {type} orders</div>;
  } else {
    return (
      <tbody className="divide-y divide-gray-200 bg-none">
        {allOrders
          .slice()
          .sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate))

          .map((order) => (
            <tr key={order.id}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                {dayjs(order.orderDate).format("lll")}
              </td>
              <td className=" px-3 py-4 text-sm">{order.id}</td>
              <td className="whitespace-nowrap px-3 py-4 text-sm ">
                {order.paymentMethod.replaceAll("_", " ")}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm ">
                GHs {order.productsCost}
              </td>{" "}
              <td className="whitespace-nowrap py-4 text-sm ">
                <Tags status={order.orderStatus} />
              </td>
              <td className="relative whitespace-nowrap py-4 pl-6 pr-4 text-left text-sm">
                <NavLink to={`details/${order.id}`}>View Order</NavLink>
                <span className="sr-only">, {order.name}</span>
              </td>
            </tr>
          ))}
      </tbody>
    );
  }
}

export default SingleOrders;
