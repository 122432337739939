import React, { Fragment } from "react";

import { ArrowLeft, CreditCard } from "react-feather";

import venda from "../../../../assets/images/products/Venda.png";

import { AiOutlineShoppingCart, AiOutlineCalendar } from "react-icons/ai";

import { Tags } from "../../../../components/Tags";

import TrackingHistory from "../../../../components/TrackingHistory";

import { Tab } from "@headlessui/react";

import { useNavigate, useParams } from "react-router-dom";

import { useSelector } from "react-redux";
import dayjs from "dayjs";

var localizedFormat = require("dayjs/plugin/localizedFormat");

const OrderDetails = () => {
  dayjs.extend(localizedFormat);

  const { orderID } = useParams();
  const order = useSelector((state) =>
    state.order.orders.find((order) => order.id === orderID)
  );

  const navigate = useNavigate();

  if (order) {
    return (
      <div>
        <button onClick={() => navigate(-1)} className="flex items-center pb-4">
          <ArrowLeft className="h-4" />
          <p className=" text-sm text-secondary-200">Go back</p>
        </button>
        <div className=" bg-neutral-50 py-8 px-8 text-secondary-200 shadow-sm rounded-lg">
          <div className="flex justify-between items-center pb-4 ">
            <div className="">
              <Tags status={order.orderStatus} />
            </div>
            {/* <button
              disabled
              className=" bg-primary-200 py-2 px-6 rounded-md text-white text-xs"
            >
              Export Details
            </button> */}
          </div>
          <p className=" text-xs">ORDER ID</p>
          <p className="text-lg font-bold">#{order.id}</p>{" "}
          <div className="flex mt-6 gap-16">
            <div className="flex gap-3 items-center justify-center">
              <div className="h-10 flex justify-center items-center w-10 rounded-full bg-primary-200">
                <CreditCard className="h-4 text-white" />
              </div>
              <div className="leading-tight">
                <p className=" text-xs">Payment Method</p>
                <p className=" text-lg font-bold">
                  {order.paymentMethod.replaceAll("_", " ")}
                </p>
              </div>
            </div>

            <div className="flex gap-3 items-center justify-center">
              <div className="h-10 flex justify-center items-center w-10 rounded-full bg-primary-200">
                <AiOutlineShoppingCart className="h-4 text-white" />
              </div>
              <div className="leading-tight">
                <p className=" text-xs">Order Total</p>
                <p className=" text-lg font-bold">GHs {order.productsCost}</p>
              </div>
            </div>

            <div className="flex gap-3 items-center justify-center">
              <div className="h-10 flex justify-center items-center w-10 rounded-full bg-primary-200">
                <AiOutlineCalendar className="h-4 text-white" />
              </div>
              <div className="leading-tight">
                <p className=" text-xs">Order Date</p>
                <p className=" text-sm font-bold">
                  {dayjs(order.orderDate).format("LLL")}
                </p>
              </div>
            </div>
          </div>
        </div>

        <Tab.Group>
          <div class="border-b border-gray-200 mt-2">
            <Tab.List class="flex -mb-px ">
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button className={selected ? "tab-active" : "tab-inactive"}>
                    <p>Order Items</p>
                  </button>
                )}
              </Tab>
              {/* <Tab as={Fragment}>
                {({ selected }) => (
                  <button className={selected ? "tab-active" : "tab-inactive"}>
                    <p>Tracking History</p>
                  </button>
                )}
              </Tab> */}
            </Tab.List>
          </div>
          <Tab.Panels className="mt-7">
            {/* Order Items */}

            <Tab.Panel>
              <div className=" grid grid-cols-3 gap-10 flex-1 space-y-7">
                {order.products.map((product) => (
                  <div className="flex pb-6 w-full justify-between items-center">
                    <div className="flex items-center gap-3">
                      <div className="h-12 w-12 rounded-full ">
                        {product.imageUrl.length > 10 ? (
                          <img
                            className="h-full w-full m-auto object-contain"
                            src={product.imageUrl}
                            alt="product"
                          />
                        ) : (
                          <img
                            className="border border-gray-300 border-dashed h-full w-full rounded-lg m-auto"
                            src={venda}
                            alt="No data"
                          />
                        )}
                      </div>
                      <div className=" ">
                        <p className=" text-secondary-200">{product.name}</p>
                        <p className=" text-[#515151] text-xs">
                          Ghs {product.price} per unit
                        </p>
                      </div>
                    </div>
                    <p className="float-right text-xs text-gray-600">
                      {" "}
                      x{product.quantity}
                    </p>
                  </div>
                ))}
              </div>
            </Tab.Panel>
            {/* History */}
            <Tab.Panel>
              <TrackingHistory order={order} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    );
  }
};

export default OrderDetails;

// <div className="py-12 ">
//   <div className="pb-8">
//     <button
//       onClick={() => setTrackingHistory(true)}
//       className={classNames(
//         trackingHistory
//           ? "mr-4 bg-primary-200 py-2 px-6 rounded-md text-white text-xs"
//           : "border border-secondary-200 py-2 px-6 rounded-md text-secondary-200 text-xs"
//       )}
//     >
//       Tracking History
//     </button>

//     <button
//       onClick={() => setTrackingHistory(false)}
//       className={classNames(
//         trackingHistory
//           ? "border border-secondary-200 py-2 px-6 rounded-md text-secondary-200 text-xs"
//           : "ml-4 bg-primary-200 py-2 px-6 rounded-md text-white text-xs"
//       )}
//     >
//       Order Items
//     </button>
//   </div>
//   {trackingHistory ? <p>Again</p> : <p>Hello</p>}
// </div>;
