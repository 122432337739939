import React from "react";
import { ScaleLoader } from "react-spinners";

const Loading = ({ text, mainText = "Loading" }) => {
  return (
    <div class="fixed top-0 left-32 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center">
      <ScaleLoader color=" #2F0247" />
      <h2 class="text-center text-2xl text-[#2F0247] font-display font-bold">
        {mainText}
      </h2>
      <p class="w-1/3 text-center ">{text}</p>
    </div>
  );
};

export default Loading;
