import React, { useEffect } from "react";

import { Link } from "react-router-dom";

import { NavLink } from "react-router-dom";

import { getAllOrders } from "../orders/orderSlice";

import { Tags } from "../../../../components/Tags";

import { useDispatch, useSelector } from "react-redux";

import dayjs from "dayjs";
var localizedFormat = require("dayjs/plugin/localizedFormat");

function DashboardRecentOrders() {
  const dispatch = useDispatch();
  const { orders, pending } = useSelector((state) => state.order);

  dayjs.extend(localizedFormat);

  useEffect(() => {
    dispatch(getAllOrders());
  }, [dispatch]);

  return (
    <div className="bg-white w-full mt-10 py-6 rounded-[7px] px-4 shadow-sm">
      <h3 className=" text-secondary-200 font-semibold">
        Recent Pending Orders
      </h3>
      {Boolean(orders.length) ? (
        <div className="mt-5">
          <table className="w-full">
            <tbody className="divide-y divide-gray-200 bg-none w-full">
              {orders
                .slice(0, 5)
                .sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate))
                .map((order) => (
                  <tr key={order.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                      {dayjs(order.orderDate).format("lll")}
                    </td>
                    <td className=" px-3 py-4 text-sm">{order.id}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm ">
                      {order.paymentMethod.replaceAll("_", " ")}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm ">
                      GHs {order.productsCost}
                    </td>{" "}
                    <td className="whitespace-nowrap py-4 text-sm ">
                      <Tags status={order.orderStatus} />
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-6 pr-4 text-left text-sm">
                      <NavLink to={`/business/orders/details/${order.id}`}>
                        View Order
                      </NavLink>
                      <span className="sr-only">, {order.name}</span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        // NO ORDERS FOUND
        <div className="flex flex-col justify-center p-20">
          <h3 className="m-auto text-xl font-bold text-center text-[#2F0247]">
            Seems you have no recent pending orders
          </h3>
          <p className="m-auto text-sm">
            Visit shop to curate products for your next order
          </p>
          <Link className="m-auto" to="/business/shop/products">
            <button
              type="button"
              className="btn-primary-full w-full md:w-full lg:w-[100%] mt-4 disabled:opacity-75 shadow-xl disabled:cursor-not-allowed enabled:hover:bg-primary-400 transition-all"
            >
              Go to shop
            </button>
          </Link>
        </div>
      )}
    </div>
  );
}

export default DashboardRecentOrders;
