import React, { useState, useEffect } from "react";

function Payment({ order, setOrder, rawDetails, setRawDetails }) {
  const handlePaymentMethod = (e) => {
    setOrder({ ...order, paymentMethod: e.target.value });
  };

  const [customerDetails, setCustomerDetails] = useState(null);

  useEffect(() => {
    setCustomerDetails(JSON.parse(localStorage.getItem("user")));
  }, []);

  useEffect(() => {
    if ("geolocation" in navigator) {
      console.log("Geolocator permitted");
      navigator.geolocation.getCurrentPosition(function (position) {
        // console.log("Latitude is :", position.coords.latitude);
        // console.log("Longitude is :", position.coords.longitude);
        setRawDetails({
          ...rawDetails,
          dropoff: {
            ...rawDetails.dropoff,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        });
      });

      // navigator.geolocation.getCurrentPosition(function (position) {
      //   console.log(position);
      // });
    } else {
      console.log("Geolocation not permitted");
    }
  }, []);

  return (
    <div>
      <div className="flex gap-20 mb-20">
        <div className="w-1/2">
          <p className=" bg-primary-100 px-6 py-1 rounded">
            Select your payment Method
          </p>
          <fieldset>
            <form className=" space-y-4" onChange={handlePaymentMethod}>
              <legend className="sr-only">Payment Method</legend>
              {/* momo on delivery */}
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="candidates"
                    aria-describedby="candidates-description"
                    name="paymentMethod"
                    value="CASH_ON_DELIVERY"
                    type="radio"
                    className="h-4 w-4 accent-primary-200"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="candidates" className="font-medium ">
                    Mobile Money on Delivery
                  </label>
                </div>
              </div>

              {/* bank transfer */}
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="offers"
                    aria-describedby="offers-description"
                    name="paymentMethod"
                    type="radio"
                    value="BANK_TRANSFER"
                    className="h-4 w-4 accent-primary-200"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="offers" className="font-medium ">
                    Bank Transfer
                  </label>
                  {/* <p
                    id="offers-description"
                    className="text-secondary-100 text-xs"
                  >
                    Payments would be verified within 48 hours before delivery
                  </p> */}
                </div>
              </div>

              {/* cheque */}
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="offers"
                    aria-describedby="offers-description"
                    name="paymentMethod"
                    type="radio"
                    value="CHEQUE"
                    className="h-4 w-4 accent-primary-200"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="offers" className="font-medium ">
                    Cheque
                  </label>
                </div>
              </div>
            </form>
          </fieldset>
        </div>

        {/* Delivery Location */}
        <div className="w-1/2">
          <div>
            <p className="mb-2 bg-primary-100 px-6 py-1 rounded w-full">
              Delivery location
            </p>
            <p className="text-xs text-gray-600 mb-5">
              * The business location used during sign up would be used for the
              delivery. Also please allow access to your location from the
              prompt
            </p>
            {/* <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="candidates"
                  aria-describedby="candidates-description"
                  name="candidates"
                  type="radio"
                  className="h-4 w-4 accent-primary-200"
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="candidates"
                  className="font-medium text-gray-700"
                >
                  Use current location
                </label>
              </div>
            </div> */}
            <input
              className=" w-full p-2 border border-gray cursor-not-allowed rounded-lg"
              type="text"
              disabled
              value={customerDetails && customerDetails.location}
            />
          </div>
        </div>

        {/* Discount deprecated */}
        {/* <div className="w-1/2">
          <p className="mb-5 bg-primary-100 px-6 py-1 rounded">
            {" "}
            Discount / Coupon Codes{" "}
          </p>
          <div className=" w-9/12 space-y-2 pb-4">
            <p className="font-medium ">Have a discount code? Enter it below</p>
            <input
              className=" border-b text-sm py-2 w-full"
              type="text"
              placeholder="Enter discount code "
            />
          </div>
          <button className=" bg-primary-200 py-2 px-6 rounded-md text-white text-xs">
            Apply Discount
          </button>
        </div> */}
      </div>
    </div>
  );
}

export default Payment;
