import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axiosInstance from "../../../../helpers/axios";

export const getProducts = createAsyncThunk(
  "products/available",
  async ({ page = 0, count = 50 }) => {
    const res = await axiosInstance.get(
      `products/available?page=${page}&count=${count}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res.data;
  }
);

export const getProductsByCategory = createAsyncThunk(
  "products/all",
  async (id) => {
    const res = await axiosInstance.get(
      `products/category/${id}?page=0&count=100`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res.data;
  }
);

export const getProductsByName = createAsyncThunk(
  "products/available",
  async (name) => {
    const res = await axiosInstance.get(`products/available?search=${name}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return res.data;
  }
);

export const getProductCategories = createAsyncThunk(
  "products/categories",
  async () => {
    const res = await axiosInstance.get("categories", {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return res.data;
  }
);

export const getDispatchEstimate = createAsyncThunk(
  "products/estimate",
  async (details) => {
    const res = await axiosInstance.post("dispatch/estimate", details, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return res.data;
  }
);

export const createDispatch = createAsyncThunk(
  "dispatch/create",
  async (details) => {
    const res = await axiosInstance.post("dispatch", details, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    console.log("create dispatch res", res);
    return res.data;
  }
);

export const productSlice = createSlice({
  name: "product",
  initialState: {
    products: [],
    categories: [],
    pending: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(getProducts.fulfilled, (state, { payload }) => {
      state.pending = false;
      state.products = payload.data;
    });
    builder.addCase(getProducts.rejected, (state) => {
      state.pending = null;
    });

    builder.addCase(getProductCategories.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(getProductCategories.fulfilled, (state, { payload }) => {
      state.pending = false;
      state.categories = payload.data;
    });
    builder.addCase(getProductCategories.rejected, (state) => {
      state.pending = false;
    });
  },
});

export default productSlice.reducer;
