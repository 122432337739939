import React from "react";
import { NavLink } from "react-router-dom";

const AuthFooter = () => {
  return (
    <div className="text-center ">
      <footer className="w-full left-0 bottom-0 mt-[-50px]">
        <div className="text-xs font-light text-secondary-200 flex justify-left m-auto w-full lg:w-[65%] items-center space-x-1">
          <div>&copy;2023 Venda. All rights reserved </div>
          <div className=" bg-secondary-200 h-1 w-1 rounded-full"></div>
          <NavLink to="/terms-of-service">
            <div>Terms</div>
          </NavLink>

          <div className=" bg-secondary-200 h-1 w-1 rounded-full"></div>
          <NavLink to="/privacy-policy">
            <div>Privacy Policy</div>
          </NavLink>
        </div>
      </footer>
    </div>
  );
};

export default AuthFooter;
