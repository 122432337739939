import React, { useEffect } from "react";

import { toast } from "react-toastify";

import services from "../../../../services";

import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

// process to order steps
import Header from "./process/Header";
import Review from "./process/Review";
import Payment from "./process/Payment";

import { clearCart, getTotals } from "../cart/cartSlice";

import Confirmation from "./process/Confirmation";
import Success from "./process/Success";

const VendaBoxIndex = () => {
  const cart = useSelector((state) => state.cart);

  const [loading, setLoading] = useState(false);

  const LESFAM_LAT = 5.6863604;
  const LESFAM_LONG = -0.1742713;
  const LESFAM_LOCATION = "LESFAM PLACE, 261 Ecowas Rd, Madina";

  const [customerDetails, setCustomerDetails] = useState(null);

  const [order, setOrder] = useState({
    paymentMethod: null,
    location: null,
    customerId: null,
    phoneNumber: "0500029755",
    products: [],
    shippingFee: null,
  });

  // update order details and delivery details with customer details
  useEffect(() => {
    setCustomerDetails(JSON.parse(localStorage.getItem("user")));
    // assign customer to raw details and final details
    let customer = JSON.parse(localStorage.getItem("user"));

    // update order
    setOrder({
      ...order,
      location: customer.location,
      customerId: customer.customerId,
      phoneNumber: "0500029755",
      products: [],
      shippingFee: null,
    });

    setRawDetails({
      ...rawDetails,
      dropoff: {
        ...rawDetails.dropoff,
        contact: {
          name: customer.businessName,
          mobile: "0549875609",
          email: customer.email,
        },
        location: customer.location,
      },
    });

    // final details
    setFinalDeliveryDetails({
      ...finalDeliveryDetails,
      contact: {
        name: customer.firstName + " " + customer.lastName,
        mobile: "0549875609",
        email: customer.email,
      },
      dropoff: {
        ...finalDeliveryDetails.dropoff,
        contact: {
          name: customer.businessName,
          mobile: "0549875609",
          email: customer.email,
        },

        location: customer.location,
      },
    });
  }, [cart]);

  const [finalDeliveryDetails, setFinalDeliveryDetails] = useState({
    pickup: {
      type: "LATLNG",
      value: "string",
      contact: {
        name: "Lesfam",
        mobile: "0549875609",
        email: "email@lesfam",
      },
      country_code: "GH",
      lat: LESFAM_LAT,
      lng: LESFAM_LONG,
      location: LESFAM_LOCATION,
    },
    dropoff: {
      type: "LATLNG",
      value: "string",
      contact: {
        name: null,
        mobile: "0549875609",
        email: null,
      },
      country_code: "GH",
      lat: null,
      lng: null,
      location: null,
    },
    items: cart.cartItems,
    contact: {
      name: null,
      mobile: "0549875609",
      email: null,
    },
    // TODO: ALLOW CUSTOMER TO ADD CUSTOM INSTRUCTIONS
    instructions: "instructions",
    reference: "",
    estimate_id: "temp",
  });

  const [rawDetails, setRawDetails] = useState({
    pickup: {
      type: "LATLNG",
      value: "string",
      contact: {
        name: "Lesfam Contact",
        mobile: "string",
        email: "email@lesfam.com",
      },
      country_code: "GH",
      lat: LESFAM_LAT,
      lng: LESFAM_LONG,
      location: LESFAM_LOCATION,
    },
    dropoff: {
      type: "LATLNG",
      value: "string",
      contact: {
        name: null,
        mobile: null,
        email: null,
      },
      country_code: "GH",
      lat: null,
      lng: null,
      location: null,
    },
    items: [],
  });

  const navigate = useNavigate();

  const [steps, setSteps] = useState("first");

  const nextSteps = () => {
    if (steps === "first") {
      setSteps("second");
      const products = cart.cartItems.map((item) => ({
        id: item.id,
        quantity: item.cartQuantity,
        // imageUrl: item.imageUrl,
      }));

      setOrder({ ...order, products: products });
    }
    if (steps === "second") {
      setSteps("third");
    }
    if (steps === "third") {
      // set customer details to order
      setLoading(true);

      let finalOrder = {
        ...order,
        products: cart.cartItems.map((item) => ({
          id: item.id,
          quantity: item.cartQuantity,
        })),
        location: customerDetails.location,
        customerId: customerDetails.id,
        phoneNumber: "0500029755",
      };

      toast.info("Creating order. Please wait...");

      services
        .createOrder(finalOrder)
        .then((res) => {
          //
          toast.success("Order created");
          toast.info("Arranging delivery. Please wait...");
          const boxID = res.data.data.id;
          let details = {
            ...finalDeliveryDetails,
            dropoff: {
              ...rawDetails.dropoff,
            },
            reference: boxID,
            // items: cart.cartItems.map(
            //   (item) =>
            //     `Name - ${item.name}, Quantity - ${item.quantity}, Unit Price - ${item.price}`
            // ),
            items: cart.cartItems.map((item) => ({
              itemImgae: item.imageUrl,
              itemName: item.name,
              id: item.id,
              itemQuantity: item.cartQuantity,
              itemCost: item.price,
              itemPrice: item.price,
              description: item.description,
            })),
          };

          services
            .createDispatch(details)
            .then((res) => {
              //
              setLoading(false);
              toast.success(res.data.message);
              setSteps("last");
            })
            .catch((e) => {
              setLoading(false);
              toast.error(e.response.data.message);
              toast.info("Please contact support for assistance");
            });
        })
        .catch((e) => {
          setLoading(false);
          toast.info("Please contact support for assistance");
          console.log("error using service", e);
        });

      // dispatch(createOrder(finalOrder))
      //   .then((res) => {
      //     console.log("in then", res);
      //     const boxID = res.data.id;
      //     const handleCreateDispatch = async () => {
      //       toast.info("Arranging delivery. Please wait...");

      //       let details = {
      //         ...finalDeliveryDetails,
      //         dropoff: {
      //           ...rawDetails.dropoff,
      //         },
      //         reference: boxID,
      //         items: cart.cartItems.map((item) => ({
      //           id: item.id,
      //           itemQuantity: item.cartQuantity,
      //           itemCost: item.price,
      //         })),
      //       };

      //       console.log("details using to create dispatch", details);
      //       try {
      //         const res = await dispatch(createDispatch(details));
      //         console.log("creating dispatch", res);
      //         // setDetails(res.payload.data);
      //       } catch (error) {
      //         console.log(error);
      //       }
      //     };

      //     handleCreateDispatch();
      //   })
      //   .catch((err) => {
      //     // error
      //     console.log("error creating dispatch");
      //   });
    }
  };

  // const handleCreateDispatch = async () => {
  //   toast.info("Arranging delivery. Please wait...");

  //   let details = {
  //     ...finalDeliveryDetails,
  //     dropoff: {
  //       ...rawDetails.dropoff,
  //     },
  //     items: cart.cartItems.map((item) => ({
  //       id: item.id,
  //       itemQuantity: item.cartQuantity,
  //       itemCost: item.price,
  //     })),
  //   };
  //   try {
  //     const res = await dispatch(createDispatch(details));
  //     console.log("creating dispatch", res);
  //     // setDetails(res.payload.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const previousSteps = () => {
    if (steps === "first") {
      navigate(-1);
    } else if (steps === "second") {
      setSteps("first");
    } else if (steps === "third") {
      setSteps("second");
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

  return (
    <div className="w-11/12 mx-auto">
      <Header
        loading={loading}
        order={order}
        previousSteps={previousSteps}
        steps={steps}
        nextSteps={nextSteps}
      />

      {steps === "first" && <Review />}

      {steps === "second" && (
        <Payment
          rawDetails={rawDetails}
          setRawDetails={setRawDetails}
          order={order}
          setOrder={setOrder}
        />
      )}

      {steps === "third" && (
        <Confirmation
          mainLoading={loading}
          setMainLoading={setLoading}
          rawDetails={rawDetails}
          setFinalDeliveryDetails={setFinalDeliveryDetails}
          finalDeliveryDetails={finalDeliveryDetails}
          order={order}
          setOrder={setOrder}
        />
      )}

      {steps === "last" && <Success />}
    </div>
  );
};

export default VendaBoxIndex;
