import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import { Menu } from "react-feather";

import Logo from "../../assets/images/logo-dark.svg";

import { PopupButton } from "@typeform/embed-react";

import Drawer from "./Drawer";

import "./index.css";

function Navbar({ howItWorksSection }) {
  const [isOpen, setIsOpen] = useState(false);

  const scrollDown = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <React.Fragment>
      <nav class="sticky top-0 py-6 backdrop-blur-xl px-8 max-w-7xl md:mx-auto">
        <div className="flex flex-row justify-between place-items-center">
          <NavLink to="/">
            <img className="w-20" src={Logo} alt="logo" />
          </NavLink>

          <ul className="menu ">
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://shopvenda.medium.com/"
                className="nav-item"
              >
                Blog
              </a>
            </li>
            <li
              onClick={() => scrollDown(howItWorksSection)}
              className="nav-item"
            >
              How it Works
            </li>
            <li>
              <a href="mailto:hello@vendasupply.com&subject=Hello Venda!&body=I'd like to know about...">
                Contact Us
              </a>
            </li>
          </ul>
          <div className="hidden md:block">
            {/* <NavLink to="/login" className="mr-5">
              Log In
            </NavLink> */}
            {/* <PopupButton
              id="E0WtokZ3"
              // style={{ fontSize: 20 }}
              className="btn-primary"
            >
              Join Waitlist
            </PopupButton> */}
            <NavLink to="/login">
              <button className="btn-primary">Sign In</button>
            </NavLink>
          </div>
          <Menu
            onClick={() => setIsOpen(!isOpen)}
            className="block md:hidden"
          />
        </div>
      </nav>
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <ul className="">
          <li className="nav-item-mobile">
            {" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://shopvenda.medium.com/"
              className="nav-item"
            >
              Blog
            </a>
          </li>
          <li
            onClick={() => {
              setIsOpen(false);
              scrollDown(howItWorksSection);
            }}
            className="nav-item-mobile"
          >
            How it Works
          </li>
          <li className="nav-item-mobile">
            {" "}
            <a href="mailto:hello@vendasupply.com">Contact Us</a>
          </li>
        </ul>
      </Drawer>
    </React.Fragment>
  );
}

export default Navbar;
