/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";

import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";

import { ChevronDown } from "react-feather";
import { BiSearch } from "react-icons/bi";
import { NavLink, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getProductCategories,
  getProducts,
  getProductsByCategory,
  getProductsByName,
} from "./products/productSlice";

import Pagination from "../../../components/Pagination";

const Shop = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProductCategories());
  }, [dispatch]);

  const [category, setCategory] = useState(null);

  const selectCategory = (category) => {
    setCategory(category);
  };

  // For adding and clearing filters
  useEffect(() => {
    if (category !== null) {
      dispatch(getProductsByCategory(category.id));
    } else {
      dispatch(getProducts());
    }
  }, [category]);

  // pagination and products
  const { categories, products, pending } = useSelector(
    (state) => state.product
  );

  const [dataPerPage, setDataPerPage] = useState(40);
  const [currentPage, setCurrentPage] = useState(0);

  // Getting products
  useEffect(() => {
    dispatch(getProducts({ page: currentPage, count: dataPerPage }));
  }, [dispatch, dataPerPage, currentPage]);

  const [name, setName] = useState("");

  useEffect(() => {
    if (name.length > 2) {
      dispatch(getProductsByName(name.toLowerCase()));
    }
    if (name.length === 0) {
      dispatch(getProducts({ page: currentPage, count: dataPerPage }));
    }
  }, [name, dispatch]);

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="hidden gap-3 md:flex ">
          <NavLink
            onClick={() => setCategory(null)}
            className={({ isActive }) =>
              isActive
                ? "bg-primary-200 text-white text-sm py-2 px-8 rounded-md"
                : "border border-secondary-200 text-secondary-200 text-sm py-2 px-8 rounded-md"
            }
            to="products"
          >
            <p onClick={() => setCategory(null)}>All Products</p>
          </NavLink>

          <div className=" flex gap-2 justify-center items-center ml-6 ">
            <p>Categories</p>
            <Menu as="div" className="relative z-10  ">
              <div>
                <Menu.Button className="flex max-w-xs items-center rounded-full  text-sm focus:outline-none   lg:rounded-md lg:p-2 ">
                  <ChevronDown
                    className="  h-4 w-5 -ml-2 flex-shrink-0 text-gray-400 lg:block"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute text-secondary-200  -left-24  mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    <div
                      onClick={() => setCategory(null)}
                      className=" hover:bg-primary-100  cursor-pointer block px-4 py-2 text-sm"
                    >
                      All Products
                    </div>
                  </Menu.Item>
                  {categories.map((category) => {
                    return (
                      <Menu.Item>
                        <div
                          onClick={() => selectCategory(category)}
                          className=" hover:bg-primary-100  cursor-pointer block px-4 py-2 text-sm"
                        >
                          {category.name}
                        </div>
                      </Menu.Item>
                    );
                  })}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>

        {/* search */}
        <div className="flex items-center gap-5">
          <div className="flex items-center border border-gray-400 px-5 py-2 rounded-xl">
            <BiSearch className="text-gray-500" />
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              className="bg-gray-100 md:w-48 w-64 ml-2"
              placeholder="Search for product..."
            />
          </div>

          <Pagination
            dataPerPage={dataPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalData={800}
          />
        </div>
      </div>
      {category && !pending && (
        <div className="mt-5 flex items-center justify-between">
          <p>
            Showing {products.length} product (s) from{" "}
            <span className="font-bold">{category.name}</span>
          </p>
          <p
            className="bg-primary-100 hover:text-white z-30 cursor-pointer p-2 text-xs hover:bg-primary-200 rounded-lg"
            onClick={() => setCategory(null)}
          >
            Clear Filters
          </p>
        </div>
      )}

      <Outlet context={[products, dataPerPage]} />
    </div>
  );
};

export default Shop;

{
  /* <p>Price</p>

            <Menu as="div" className="relative z-10  ">
              <div>
                <Menu.Button className="flex max-w-xs items-center rounded-full  text-sm focus:outline-none   lg:rounded-md lg:p-2 ">
                  <ChevronDown
                    className=" -ml-2  h-4 w-5 flex-shrink-0 text-gray-400 lg:block"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute text-secondary-200 right-0  mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    <NavLink to="#" className=" block px-4 py-2 text-xs">
                      Processed food
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item>
                    <NavLink to="#" className=" block px-4 py-2 text-xs">
                      Prepared meals
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item>
                    <NavLink to="#" className=" block px-4 py-2 text-xs">
                      Beverages
                    </NavLink>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu> */
}
