import React, { useEffect, useState } from "react";

const Settings = () => {
  const [user, setUser] = useState({});

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
  }, []);

  let inputStyle =
    "py-2 px-4 w-full mt-3 bg-[#F3F3F3] rounded-xl disabled:cursor-not-allowed border";

  return (
    <div className=" bg-white text-secondary-200 p-12">
      <h4 className="font-bold">Business Details</h4>
      <p className="border w-20  border-primary-200 mt-1 "></p>
      <div>
        <form className=" py-6" action="">
          <div className=" w-6/12 ">
            <label className=" text-sm" htmlFor="">
              Company Name
            </label>
            <input
              className={inputStyle}
              type="text"
              name=""
              id=""
              disabled
              value={user?.businessName}
            />
          </div>

          <div className=" w-6/12 mt-4 ">
            <label className=" text-sm" htmlFor="">
              Email Address
            </label>
            <input
              className={inputStyle}
              type="text"
              name=""
              id=""
              disabled
              value={user.email}
            />
          </div>

          <div className=" w-6/12 mt-4 ">
            <label className=" text-sm" htmlFor="">
              Location
            </label>
            <input
              className={inputStyle}
              type="text"
              name=""
              disabled
              id=""
              value={user.location}
            />
          </div>

          <div className=" w-6/12 mt-4 ">
            <label className=" text-sm" htmlFor="">
              Phone Number
            </label>
            <input
              className={inputStyle}
              type="number"
              name=""
              disabled
              id=""
              value={user.phoneNumber}
            />
          </div>

          {/* <div className=" w-6/12 mt-6 ">
            <button className=" bg-primary-200 py-2 text-xs text-white px-4 rounded-md">
              Save Changes
            </button>
          </div> */}
        </form>
      </div>
    </div>
  );
};

export default Settings;
