import { useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { authenticateUser } from "../userSlice";
import { useRef } from "react";
import Loading from "../../../components/Loading";

const Verification = () => {
  const navigate = useNavigate();
  const { pending } = useSelector((state) => state.user);

  const dataFetchedRef = useRef(false);
  let param = useParams();
  const dispatch = useDispatch();

  const authenticate = async () => {
    try {
      await dispatch(
        authenticateUser({
          email: param.email,
          magicLink: param.magicLink,
        })
      ).unwrap();
      navigate("/business/dashboard");

      toast.success("Authenticated");
    } catch (error) {
      if (error.message === "Rejected") {
        toast.error(`Network error`);
      } else {
        toast.error(`${error.error}`);
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    authenticate();
  });
  return (
    <div>
      <div className="mx-auto">
        <div className="flex h-screen text-secondary-200 ">
          {pending ? (
            <div>
              <Loading text=" This may take a few seconds, please don't close this page." />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Verification;
