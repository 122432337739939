import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";

import { IoIosArrowDown } from "react-icons/io";

import Image from "../../../../../components/Image";

import { Disclosure } from "@headlessui/react";

import { useDispatch, useSelector } from "react-redux";

import { getDispatchEstimate } from "../../products/productSlice";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Confirmation({
  order,
  setOrder,
  finalDeliveryDetails,
  setFinalDeliveryDetails,
  rawDetails,
  mainLoading,
  setMainLoading,
}) {
  const cart = useSelector((state) => state.cart);

  const [loading, setLoading] = useState(false);

  const [estimates, setEstimates] = useState(null);

  useEffect(() => {
    //
    toast.info("Getting delivery estimate. Please wait...");
    getEstimate(rawDetails);
  }, [rawDetails]);

  const dispatch = useDispatch();

  const getEstimate = async (details) => {
    setLoading(true);
    setMainLoading(true);
    try {
      const res = await dispatch(getDispatchEstimate(details));
      setEstimates(res.payload.data.estimates[0]);
      toast.success(res.payload.message);
      setLoading(false);
      setMainLoading(false);
      setFinalDeliveryDetails({
        ...finalDeliveryDetails,
        estimate_id: res.payload.data.estimates[0].estimate_id,
      });
      setOrder({
        ...order,
        shippingFee: res.payload.data.estimates[0].full_price,
      });
    } catch (error) {
      setLoading(false);
      setMainLoading(false);
      toast.error("Error occured getting delivery estimate");
      console.log("error getting delivery estimate", error);
    }
  };

  return (
    <div className="mb-20">
      <div>
        {/* Order Summary */}
        <Disclosure as="div" defaultOpen={true}>
          {({ open }) => (
            <>
              <dt className="rounded-lg py-1 px-6 bg-primary-100">
                <Disclosure.Button className="flex w-full items-center justify-between text-left ">
                  <p className="font-bold">Order Summary</p>
                  <span className="ml-6 flex h-7 items-center">
                    <IoIosArrowDown
                      className={classNames(
                        open ? "-rotate-180" : "rotate-0",
                        "h-5 w-5 transform"
                      )}
                      aria-hidden="true"
                    />
                  </span>
                </Disclosure.Button>
              </dt>
              <Disclosure.Panel as="dd" className="mt-2 pr-12">
                {loading ? (
                  <div
                    className="w-full min-h-[20vh] flex items-center justify-center text-center
                  "
                  >
                    <div>
                      <p className="text-gray-900 font-semibold text-lg">
                        Getting Delivery Estimates and Cart Total
                      </p>
                      <p className="text-gray-500 text-sm">Please wait...</p>
                    </div>
                  </div>
                ) : (
                  <div className="text-lg">
                    {/* BOX TOTAL, DELIVRY AND VENDA SERVICE FEE */}
                    <div className="my-6 text-base  space-y-3 w-11/12 mx-auto">
                      <div className="flex justify-between">
                        <p>Venda Box Total</p>
                        <p>Ghs {cart.cartTotalAmount}</p>
                      </div>

                      <div className="flex justify-between">
                        <p>Delivery fee</p>
                        <p>Ghs {estimates && estimates.full_price}</p>
                      </div>
                      <div className="flex justify-between">
                        <p>Service Fee</p>
                        <p>Ghs {(cart.cartTotalAmount * 0.05).toFixed(2)}</p>
                      </div>
                    </div>
                    <hr />
                    {/* GOVERNMENT TAXES */}
                    <div className="my-6 text-base  space-y-3 w-11/12 mx-auto">
                      <div className="flex justify-between">
                        <p>3% Flat Rate</p>
                        <p>Ghs {(cart.cartTotalAmount * 0.03).toFixed(2)}</p>
                      </div>
                      <div className="flex justify-between">
                        <p>1% COVID-19 Levy </p>
                        <p>Ghs {(cart.cartTotalAmount * 0.01).toFixed(2)}</p>
                      </div>
                    </div>

                    {/* Grand Total */}
                    <hr />
                    <div className="my-6 text-base  space-y-3 w-11/12 mx-auto">
                      <div className="flex justify-between  font-bold">
                        <p className="mt-4 text-lg">Grand Total</p>
                        <p className="mt-4">
                          Ghs{" "}
                          {(
                            cart.cartTotalAmount +
                            order.shippingFee +
                            cart.cartTotalAmount * 0.05 +
                            cart.cartTotalAmount * 0.03 +
                            cart.cartTotalAmount * 0.01
                          ).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* Delivery Details */}
        <Disclosure as="div" className="pt-6">
          {({ open }) => (
            <>
              <dt className="rounded-lg py-1 px-6 bg-primary-100">
                <Disclosure.Button className="flex w-full items-center justify-between text-left ">
                  <p className="font-bold">Payment & Delivery </p>
                  <span className="ml-6 flex h-7 items-center">
                    <IoIosArrowDown
                      className={classNames(
                        open ? "-rotate-180" : "rotate-0",
                        "h-5 w-5 transform"
                      )}
                      aria-hidden="true"
                    />
                  </span>
                </Disclosure.Button>
              </dt>
              <Disclosure.Panel as="dd" className="mt-2 pr-12">
                <div className="text-base">
                  <div>
                    <div className="my-6 text-base space-y-3 w-11/12 mx-auto">
                      <div className="flex justify-between">
                        <p>Payment method</p>
                        <p className="font-bold">
                          {order.paymentMethod.replaceAll("_", " ")}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <p>Delivery Location</p>
                        <p>{order.location}</p>
                      </div>
                      <div className="flex justify-between">
                        <p>Delivery Date & Time Estimates</p>
                        <p>{estimates && estimates.time_string}</p>
                      </div>

                      <div className="flex justify-between">
                        <p>Total Distance</p>
                        <p>{estimates && estimates.total_distance}km</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        {/* {deliveryDetails?.time_string} */}

        <Disclosure as="div" className="pt-6">
          {({ open }) => (
            <>
              <dt className="rounded-lg py-1 px-6 bg-primary-100">
                <Disclosure.Button className="flex w-full items-center justify-between text-left ">
                  <p className="font-bold"> Venda Box Items </p>
                  <span className="ml-6 flex h-7 items-center">
                    <IoIosArrowDown
                      className={classNames(
                        open ? "-rotate-180" : "rotate-0",
                        "h-5 w-5 transform"
                      )}
                      aria-hidden="true"
                    />
                  </span>
                </Disclosure.Button>
              </dt>
              <Disclosure.Panel as="dd" className="mt-2 pr-12">
                <div className="grid grid-cols-1 md:grid-cols-2">
                  {cart.cartItems?.map((cartItem) => (
                    <div
                      key={cartItem.id}
                      className="flex items-center justify-between text-secondary-200 pb-16"
                    >
                      <div className=" flex items-center gap-6">
                        <Image url={cartItem.imageUrl} />
                        <div>
                          <p className=" text-lg font-bold">{cartItem.name}</p>
                          <p className=" text-xs">
                            Ghs {cartItem.price} per unit
                          </p>
                          <p className="text-xs">
                            Qty: {cartItem.cartQuantity}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}

export default Confirmation;
