import React from "react";
import { Disclosure } from "@headlessui/react";

import Footer from "../../app/components/Footer";

import Navbar from "../../app/components/Navbar";

import { IoIosArrowDown } from "react-icons/io";

const faqs = [
  {
    question: "Personal Information we collect",
    position: 1,
    answer: `When you visit the Site, we automatically collect certain information about your device,
including information about your web browser, IP address, time zone, and some of the cookies
that are installed on your device.
Additionally, as you browse the Site, we collect information about the individual web pages or
products that you view, what websites or search terms referred you to the Site, and
information about how you interact with the Site. We refer to this automatically collected
information as Device Information.

We collect Device Information using the following technologies:
 Cookies are data files that are placed on your device or computer and often include an
anonymous unique identifier.
 Log files track actions occurring on the Site, and collect data including your IP address,
browser type, Internet service provider, referring/exit pages, and date/time stamps.

Also, when you make a purchase or attempt to make a purchase through the Site, we collect
certain information from you, including your name, billing address, shipping address, payment
information (including credit card numbers, all types of accepted payments, email address, and
phone number.) This is called Order Information. By Personal Information in this Privacy Policy, we are talking both about Device Information
and Order Information.`,
  },
  {
    question: "Usage of Personal Information",
    answer: `We use the Order Information that we collect generally to fulfil any orders placed through the
Site (including processing your payment information, arranging for delivery, and providing you
with invoices and/or order confirmations).
Additionally, we use this Order Information to:
 Communicate with you.
 Screen our orders for potential risk or fraud.
 When in line with the preferences you have shared with us, provide you with
information or advertising relating to our products or services.

We use the Device Information that we collect to help us screen for potential risk and
fraud (in particular, your IP address), and more generally to improve and optimize our
Site.`,
  },
  {
    question: "Sharing your Personal Information",
    answer: `We share your Personal Information with third parties to help us use your Personal
Information, as described above.
We also use Google Analytics to help us understand how our customers use (Venda). How
Google uses your Personal Information.
Finally, we may also share your Personal Information to comply with applicable laws and
regulations, to respond to a subpoena, search warrant or other lawful requests for information
we receive, or to otherwise protect our rights.`,
  },
  {
    question: "Behaviorial Advertising",
    answer: `We use your Personal Information to provide you with targeted advertisements or marketing
communications we believe may be of interest to you.`,
  },
  {
    question: "Data Retention",
    answer: `When you place an order through the Site, we will maintain your Order Information for our
records unless and until you ask us to delete this information.`,
  },
  {
    question: "Minors",
    answer: `The Site is not intended for individuals under the age of (18)..`,
  },
  {
    question: "Changes",
    answer: `We may update this privacy policy from time to time in order to reflect, for example, changes
to our practices or for other operational, legal or regulatory reasons.
If you have questions and/or require more information, do not hesitate to contact
us (help@shopvenda.co).`,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PrivacyPolicy = () => {
  // scroll to top
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className=" bg-primary-100 text-secondary-200 px-12">
        <Navbar />
        <div className=" text-center  py-10 pt-5 ">
          <h5 className="text-4xl font-bold">Privacy Policy</h5>
          <p className="max-w-2xl mx-auto">
            This Privacy Policy describes how your personal information is
            collected, used, and shared when you visit or make a purchase from
            Venda ({window.location.host}).
          </p>
          <p className="text-sm mt-4 max-w-xl mx-auto">
            Last Updated on: 16th April, 2023
          </p>
        </div>
      </div>
      <div className="mx-auto max-w-7xl py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-5xl divide-y-2 divide-gray-200">
          <dl className="space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure
                as="div"
                defaultOpen={faq.position === 1}
                key={faq.question}
                className="pt-6 "
              >
                {({ open }) => (
                  <>
                    <dt className="text-lg rounded-lg py-2 px-4 bg-primary-100">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left ">
                        <span className="font-semibold text-secondary-200 ">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <IoIosArrowDown
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-5 w-5 transform"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
