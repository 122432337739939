import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axiosInstance from "../../helpers/axios";

export const signInUser = createAsyncThunk(
  "users/login",
  async (user, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post("/auth/customers/login", user);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const registerUser = createAsyncThunk(
  "users/register",
  async (user, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post("/auth/customers/register", user);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const authenticateUser = createAsyncThunk(
  "users/authenticate",
  async (user, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(
        "/auth/customers/authenticate",
        user
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//get the user profile
export const getUser = createAsyncThunk(
  "users/id",
  async (user, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get("/auth/customers/profile/id", user);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {
      businessName: "",
      fullName: "",
      lastName: "",
      location: "",
      email: "",
    },

    pending: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(registerUser.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(registerUser.fulfilled, (state, { payload }) => {
      state.pending = false;
      state.user = payload;
    });

    builder.addCase(registerUser.rejected, (state) => {
      state.pending = null;
    });

    builder.addCase(authenticateUser.pending, (state) => {
      state.pending = true;
    });

    builder.addCase(authenticateUser.fulfilled, (state, { payload }) => {
      state.pending = false;
      localStorage.setItem("user", JSON.stringify(payload.data));
      localStorage.setItem("token", payload.data.accessToken);
      state.user = payload.data;
    });

    builder.addCase(authenticateUser.rejected, (state) => {
      state.pending = null;
    });

    builder.addCase(signInUser.pending, (state) => {
      state.pending = true;
    });

    builder.addCase(signInUser.fulfilled, (state, { payload }) => {
      state.pending = false;
      state.user = payload.data;
    });

    builder.addCase(signInUser.rejected, (state) => {
      state.pending = null;
    });
  },
});

export default userSlice.reducer;
