import { NavLink } from "react-router-dom";
import heroImage from "../../../assets/images/signin.png";

import { useState, useEffect } from "react";

// import AuthFooter from "../../../components/AuthFooter";

import { useDispatch, useSelector } from "react-redux";
import { signInUser } from "../userSlice";
import { Mail } from "react-feather";
import { toast } from "react-toastify";
// import { useFormik } from "formik";
// import { loginSchema } from "../../../schemas";

import { handleRecaptchaSubmission } from "../../../utils/captcha";

import Logo from "../../../assets/images/logo-dark.svg";

import Modal from "../forgetPassword/Modal";

const SignIn = () => {
  const { pending } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  // CAPTCHA Implementation
  const [loading, setLoading] = useState(false);
  const [allowAuth, setAllowAuth] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // const {
  //   values,
  //   handleBlur,
  //   errors,
  //   touched,
  //   isValid,
  //   handleChange,
  //   handleSubmit,
  // } = useFormik({
  //   initialValues: {
  //     email: "",
  //   },
  //   validationSchema: loginSchema,
  //   validateOnMount: true,

  //   onSubmit: async (values, actions) => {
  //     try {
  //       const res = await dispatch(
  //         signInUser({ email: values.email })
  //       ).unwrap();
  //       navigate("/confirm-email");
  //       toast.success(`${res.message}`);

  //       // reset form
  //       actions.resetForm();

  //       // END OF TRY
  //     } catch (error) {
  //       if (error.message === "Rejected") {
  //         toast.error(`Error occured. Please refresh and try again`);
  //       } else {
  //         toast.error(`${error.message}`);
  //       }
  //     }
  //   },
  // });

  const [email, setEmail] = useState("");

  // handle sign in using data and captcha
  useEffect(() => {
    let signIn = async () => {
      try {
        const res = await dispatch(signInUser({ email })).unwrap();
        setLoading(false);
        setShowModal(true);
        setAllowAuth(false);
        // toast.success(`${res.message}`);

        // END OF TRY
      } catch (error) {
        if (error.message === "Rejected") {
          setLoading(false);
          setAllowAuth(false);
          toast.error(`Error occured. Please refresh and try again`);
        } else {
          setLoading(false);
          setAllowAuth(false);
          toast.error(`${error.message}`);
        }
      }
    };

    if (allowAuth) {
      signIn();
    }
  }, [allowAuth]);

  // initialize recaptcha
  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_SITE_KEY}`,
      function () {
        // console.log("Script loaded!");
      }
    );
  }, []);

  return (
    <div className=" mx-auto">
      <Modal
        email={email}
        setEmail={setEmail}
        isOpen={showModal}
        setIsOpen={setShowModal}
      />
      <div className=" flex h-screen">
        <div className="lg:w-1/2 w-full px-8 mx-auto relative ">
          <div className=" lg:w-8/12 w-full mx-auto pt-16 h-4/6">
            <NavLink to="/">
              <img className="w-20 ml-[-10px]" src={Logo} alt="logo" />
            </NavLink>
            <div className=" flex h-full place-items-center">
              <div className=" w-full lg:w-10/12 ">
                <div className=" text-secondary-200 ">
                  <h4 className=" text-xl lg:text-h4  font-bold ">
                    Welcome back!
                  </h4>
                  <p className="text-sm">
                    New to Venda? {"  "}
                    <NavLink to="/signup">
                      <span className=" text-primary-200">Sign Up</span>
                    </NavLink>
                  </p>
                  <form
                    onSubmit={(e) =>
                      handleRecaptchaSubmission(e, setLoading, setAllowAuth)
                    }
                  >
                    <div className="flex bg-[#f4f4f4] relative my-8">
                      <Mail
                        className="absolute font-medium  inset-0 h-9 w-5 flex-shrink-0 text-2xl"
                        aria-hidden="true"
                      />
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="border-b w-full focus:bg-[#f4f4f4] active:bg-[#f4f4f4] bg-[#f4f4f4] border-secondary-200 text-sm px-8 py-2"
                        placeholder="Email address"
                      />
                    </div>

                    <button
                      // onClick={() => handleRecaptchaSubmission()}
                      disabled={
                        !email.includes("@") || email.length < 5 || loading
                      }
                      className="btn-primary-full w-full flex place-content-center mt-4 mb-7 disabled:opacity-75 shadow-xl disabled:cursor-not-allowed enabled:hover:bg-primary-400 transition-all"
                    >
                      {pending || loading ? (
                        <div role="status">
                          <svg
                            aria-hidden="true"
                            className="w-6 h-6 mr-2 text-center text-gray-200 animate-spin dark:text-gray-600 fill-white"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        </div>
                      ) : (
                        <> Sign In</>
                      )}
                    </button>
                  </form>
                  {/* <div className=" ">
                    <div className="flex items-center j">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded  border-secondary-200 text-secondary-200 focus:secondary-200"
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-2 block text-xs"
                      >
                        Remember me on this device
                      </label>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[60px]">{/* <AuthFooter /> */}</div>
        </div>

        <div className="hidden md:inline w-1/2">
          <div className="bg-primary-400 h-screen w-full">
            <img
              className="h-full bg-primary-400 w-full object-cover"
              src={heroImage}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
