import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter your email"),
});

export const registerSchema = yup.object().shape({
  businessName: yup.string().required("Please enter a business name"),
  firstName: yup.string().max(10).required("Please enter your first name"),
  lastName: yup.string().required("Please enter your last name"),
  location: yup.string().required("Please enter your location"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter an email"),
});
