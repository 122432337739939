// import { ArrowRight, Calendar, MoreHorizontal } from "react-feather";
import React, { useEffect, useState } from "react";
import AnalyticsCard from "../../../components/AnalyticsCard";

import DashboardRecentOrders from "./dashboard/DashboardRecentOrders";
// import LineChart from "../../../components/Charts/LineChart";

import { getAllOrders } from "./orders/orderSlice";

import { useDispatch, useSelector } from "react-redux";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { orders, pending } = useSelector((state) => state.order);

  const [pendingOrders, setPendingOrders] = useState(null);
  const [completedOrders, setCompletedOrders] = useState(null);

  // get pending orders
  useEffect(() => {
    let tempPending = [];
    let tempCompleted = [];
    if (orders) {
      for (let i = 0; i < orders.length; i++) {
        //
        if (orders[i].orderStatus.toLowerCase() === "pending") {
          tempPending.push(orders[i]);
        }
        if (orders[i].orderStatus.toLowerCase() === "completed") {
          tempPending.push(orders[i]);
        }
      }

      setPendingOrders(tempPending.length);
      setCompletedOrders(tempCompleted.length);
    }
  }, [orders]);

  useEffect(() => {
    dispatch(getAllOrders());
  }, [dispatch]);
  return (
    <div>
      <div className="grid grid-cols-auto py-14 lg:space-x-6  gap-y-4">
        <div className="col-span-12 lg:col-span-9 ">
          <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-7 ">
            <AnalyticsCard
              title={"Total Products Available"}
              action={"Start shopping"}
              icon={"FiBox"}
              body={"860"}
              col={0}
              linkTo={"/business/shop/products"}
            />
            <AnalyticsCard
              title={"Pending Orders"}
              action={"Go to Orders"}
              icon={"FiShoppingCart"}
              body={pendingOrders}
              col={1}
              linkTo={"/business/orders"}
            />
            <AnalyticsCard
              title={"Completed Orders"}
              action={"Go to Orders"}
              icon={"FiCheckCircle"}
              body={completedOrders}
              col={2}
              linkTo={"/business/orders"}
            />
            {/* <AnalyticsCard
              title={"Cancelled Orders"}
              action={"See all orders"}
              icon={"FiXCircle"}
              body={"1"}
              col={2}
              linkTo={"/business/orders"}
            /> */}
          </div>
          <DashboardRecentOrders />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
