import axios from "axios";

const baseURL = "https://venda-api.onrender.com/api/v1";

// ENDPOINTS

export const createOrder = (box) => {
  return axios({
    method: "POST",
    url: `${baseURL}/boxes`,
    data: box,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
};

export const createDispatch = (data) => {
  console.log("creating dispatch with", data);
  return axios({
    method: "POST",
    url: `${baseURL}/dispatch`,
    data,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
};

export const verifyCaptcha = (token) => {
  return axios({
    method: "POST",
    url: `${baseURL}/verify-captcha`,
    data: { token },
  });
};

export const dispatchStatus = (code) => {
  return axios({
    method: "GET",
    url: `${baseURL}/dispatch/${code}`,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
};
