import React from "react";

export const Tags = ({ status }) => {
  return (
    <div className="">
      {status === "PENDING" ? (
        <div className=" bg-orange-400  py-[2px] px-4 rounded-lg text-center text-white text-xs">
          {status}
        </div>
      ) : status === "COMPLETE" ? (
        <div className="bg-success-200  py-[2px] text-center rounded-lg text-white text-xs">
          {status}
        </div>
      ) : status === "CANCELLED" ? (
        <div className="bg-red-500  py-[2px] text-center rounded-lg text-white text-xs">
          {status}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
