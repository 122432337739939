import { toast } from "react-toastify";

import { verifyCaptcha } from "../services/restService";

export const handleRecaptchaSubmission = (e, setLoading, setAllowAuth) => {
  setLoading(true);
  e.preventDefault();
  window.grecaptcha.ready(() => {
    window.grecaptcha
      .execute(process.env.REACT_APP_CAPTCHA_SITE_KEY, { action: "submit" })
      .then((token) => {
        submitData(token, setAllowAuth);
      });
  });
};

// verify score from google services
export const submitData = (token, setAllowAuth) => {
  verifyCaptcha(token).then((res) => {
    setAllowAuth(res.data.status);
    if (!res.data.status) {
      toast.error(`Error occured. Please refresh and try again`);
    }
  });
};
