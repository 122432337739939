import noteLogo from "../../../../assets/icons/note.svg";
import PlusButton from "../../../../components/PlusButton";

// import { Tag } from "../../../../components/Tag";

import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import venda from "../../../../assets/images/products/Venda.png";
import { addToCart, getTotals } from "../cart/cartSlice";
import { CloseCircle } from "iconsax-react";

import { useNavigate } from "react-router-dom";

const ProductCard = ({
  image,
  name,
  description,
  oldPrice,
  newPrice,
  type,
  value,
  id,
}) => {
  let [isOpen, setIsOpen] = useState(false);

  let navigate = useNavigate();

  const product = useSelector((state) =>
    state.product.products.find((product) => product.id === id)
  );
  const [quantity, setQuantity] = useState(1);

  const setModal = () => {
    setIsOpen(true);
  };
  const dispatch = useDispatch();
  const handleAddToCart = (product) => {
    dispatch(addToCart({ ...product, quantity }));
    dispatch(getTotals());
    setIsOpen(false);
    setQuantity(1);
  };

  function closeModal() {
    setIsOpen(false);
    setQuantity(1);
  }

  function handleMultipleClicks() {
    setQuantity(quantity + 1);
    // handleAddToCart(product)
    setIsOpen(true);
  }

  return (
    <div className="relative cursor-pointer  box-border h-full w-64 mb-4 justify-between  text-secondary-400">
      <div className=" h-36 transition-all rounded-xl items-end flex">
        <div onClick={() => setModal()} className="w-full px-3 mb-4">
          <div className=" flex items-center ml-4  gap-2">
            <img src={noteLogo} alt="" />

            <p className=" text-sm font-display font-medium">{newPrice}</p>
          </div>

          <div className=" flex items-center justify-between">
            <div className=" w-44">
              <h5 className="truncate w-full ml-4 text-md font-bold">{name}</h5>
              <p className="truncate  text-xs ml-4 font-light">{description}</p>
            </div>
          </div>
        </div>
        <div className=" items-center justify-center flex mb-6 mr-6">
          <PlusButton product={product} />
        </div>
      </div>
      <div
        onClick={() => setModal()}
        className=" h-40 w-52 mx-auto bg-white  border-2 rounded-2xl absolute inset-0 -top-28"
      >
        {image.length > 10 ? (
          <img
            onClick={() => setModal()}
            className="w-full h-full p-2 m-auto object-contain"
            src={image}
            alt="product"
          />
        ) : (
          <img
            onClick={() => setModal()}
            className="w-24 h-11 m-auto mt-14 object-contain"
            src={venda}
            alt="No data"
          />
        )}
      </div>

      <>
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 backdrop-blur-sm bg-white/30" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-8/12 transform overflow-hidden rounded-2xl bg-white p-16 text-left align-middle border border-purple-600 shadow-xl transition-all">
                    <button
                      className="display flex overflow text-3xl px-20 w-full justify-end"
                      onClick={() => setIsOpen(false)}
                    >
                      <div>
                        <CloseCircle />
                      </div>
                    </button>
                    <div className="w-full mx-auto flex flex-col lg:flex-row py-8">
                      <div className=" w-4/6 m-auto flex">
                        <div className="w-4/6">
                          {/* <img className="h-full" src={product.imageUrl} alt="" /> */}
                          {image.length > 10 ? (
                            <img
                              onClick={() => setModal()}
                              className="w-auto h-48 m-auto object-contain"
                              src={image}
                              alt="product"
                            />
                          ) : (
                            <img
                              onClick={() => setModal()}
                              className="w-24 h-24 m-auto object-contain"
                              src={venda}
                              alt="No data"
                            />
                          )}
                        </div>
                      </div>
                      <div className="w-full">
                        <h2 className="text-3xl font-bold ">
                          <p className="capitalize"> {product.name} </p>
                        </h2>

                        <p className="text-sm font-light mb-5">
                          {product.description}
                        </p>
                        <hr />
                        <div className="flex gap-20">
                          {/* unit price */}
                          <div>
                            <p className="mt-5 mb-2"> Unit Price </p>
                            <h2 className="text-lg font-semibold  text-[#2F0247] mb-4">
                              GHs {product.price}
                            </h2>
                          </div>

                          {/* Quantity */}
                          <div>
                            <p className="mt-5 mb-2"> Quantity</p>
                            <div className="flex items-center space-x-4 cursor-pointer">
                              <button
                                disabled={quantity === 1}
                                onClick={() => setQuantity(quantity - 1)}
                                className=" bg-[#F7F7F7] px-3 py-1 disabled:bg-gray-50 disabled:cursor-not-allowed cursor-pointer"
                              >
                                -
                              </button>
                              <span className=" font-bold w-4 mx-auto text-center ">
                                {quantity}
                              </span>
                              <button
                                onClick={() => handleMultipleClicks()}
                                className=" bg-[#F7F7F7] px-3 py-1 cursor-pointer w-[27.98px] h-[27.36px]"
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className=" space-x-4 mt-8">
                          <button
                            onClick={() => {
                              setIsOpen(false);
                              handleAddToCart(product);

                              navigate("/business/orders/venda-box");
                            }}
                            className="border border-gray-300 px-8 py-2 text-sm rounded-xl"
                          >
                            Buy now
                          </button>
                          <button
                            onClick={() => {
                              setIsOpen(false);
                              handleAddToCart(product);
                            }}
                            className="bg-primary-200 text-white px-8 py-2 text-sm rounded-xl"
                          >
                            Add to Venda Box
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>

      {/* <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-[#c8c8c8]/50 transition-opacity h-screen w-screen inset-0 z-10 fixed flex justify-center items-center" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="text-secondary-200 transform transition-all bg-white  bottom-0 absolute rounded-t-[120px] shadow-2xl">
                  <button
                    onClick={() => setOpen(false)}
                    className=" text-3xl px-20 mt-12 border-none"
                  >
                    x
                  </button>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root> */}
    </div>
  );
};

export default ProductCard;
