import { useNavigate } from "react-router-dom";
import error from "../../assets/images/404.svg";
const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className="flex h-screen z-100">
      <div className=" place-self-center mx-auto text-center  text-secondary-200  h-full  items-center flex ">
        <div className=" w-9/12 lg:w-full mx-auto  justify-center">
          <h3 className=" text-h5 font-bold lg:text-h3 ">Venda</h3>
          <h2 className="text-h4 lg:text-h2 font-bold mt-8">
            Yikes! Page not found
          </h2>
          <h5>Apologies. Our engineers would make sure this is resolved</h5>
          <div className=" lg:w-8/12 mx-auto  mt-8 lg:mt-4">
            <img src={error} alt="" />
          </div>
          <div className=" mt-12">
            <button
              onClick={() => navigate("/shop")}
              className="btn-primary-full w-7/12 lg:w-5/12 mt-10 mb-7 shadow-xl hover:bg-primary-400 hover:shadow-xl transition-all"
            >
              Go to Shop
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
