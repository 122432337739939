import React from "react";
import { Disclosure } from "@headlessui/react";

import Footer from "../../app/components/Footer";

import Navbar from "../../app/components/Navbar";

import { IoIosArrowDown } from "react-icons/io";

const faqs = [
  {
    question: "Venda is located at: 8 Banana Street, Accra, Ghana.",
    position: 1,
    answer: `By accessing this website, we assume you accept these terms and conditions in full. Do not
continue to use Venda’s website if you do not accept all of the terms and conditions stated on
this page.`,
  },
  {
    question: "Cookies",
    answer: `We employ the use of cookies. By using Venda’s website, you consent to the use of cookies in
accordance with Venda’s privacy policy. Most of the modern day interactive websites use
cookies to enable us to retrieve user details for each visit.

Cookies are used in some areas of our site to enable the functionality of this area and ease of
use for those people visiting. Some of our affiliate / advertising partners may also use cookies.`,
  },
  {
    question: "License",
    answer: `Unless otherwise stated, Venda and/or its licensors own the intellectual property rights for all
material on Venda.

All intellectual property rights are reserved. You may view and/or print pages from
(www.shopvenda.co) for your own personal use subject to restrictions set in these terms and
conditions.

You must not:

 Republish material
 Sell, rent or sub-license material
 Reproduce, duplicate or copy material
 Redistribute content from (Store Name) (unless content is specifically made for
redistribution).`,
  },
  {
    question: "Disclaimer",
    answer: `To the maximum extent permitted by applicable law, we exclude all representations, warranties
and conditions relating to our website and the use of this website (including, without limitation,
any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the
use of reasonable care and skill).

Nothing in this disclaimer will:
 Limit or exclude our or your liability for death or personal injury resulting from
negligence.
 Limit or exclude our or your liability for fraud or fraudulent misrepresentation.
 Limit any of our or your liabilities in any way that is not permitted under applicable law.
 Or exclude any of our or your liabilities that may not be excluded under applicable law.

The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer:
1. are subject to the preceding paragraph; and
2. govern all liabilities arising under the disclaimer or in relation to the subject matter of
this disclaimer, including liabilities that arise in contract, tort (including negligence) and
for breach of statutory duty.
To the extent that the website and the information and services on the website are provided
free of charge, we will not be liable for any loss or damage of any nature.`,
  },

  // More questions...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TermsOfService() {
  // scroll to top
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className=" bg-primary-100 text-secondary-200 px-12">
        <Navbar />
        <div className=" text-center  py-10 pt-5 ">
          <h5 className="text-4xl font-bold">Terms of Service</h5>
          <p className="max-w-2xl mx-auto">
            Welcome to Venda. These terms and conditions outline the rules and
            regulations for the use of Venda’s Website.
          </p>
        </div>
      </div>
      <div className="mx-auto max-w-7xl py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-5xl divide-y-2 divide-gray-200">
          <dl className="space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure
                as="div"
                defaultOpen={faq.position === 1}
                key={faq.question}
                className="pt-6"
              >
                {({ open }) => (
                  <>
                    <dt className="text-lg rounded-lg py-2 px-4 bg-primary-100">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left ">
                        <span className="font-semibold text-secondary-200 ">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <IoIosArrowDown
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-5 w-5 transform"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
      <Footer />
    </div>
  );
}
