import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../../helpers/axios";

import { toast } from "react-toastify";

export const getAllOrders = createAsyncThunk("orders", async () => {
  const res = await axiosInstance.get("/boxes/my-boxes", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return res.data;
});

export const createOrder = createAsyncThunk(
  "orders/create-order",
  async (order) => {
    const res = await axiosInstance.post("/boxes", order, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    toast.info("Order created successfully");
    console.log("create order", res.data);
    return res.data;
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    orders: [],
    pending: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllOrders.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(getAllOrders.fulfilled, (state, { payload }) => {
      state.pending = false;
      state.orders = payload.data;
    });
    builder.addCase(getAllOrders.rejected, (state) => {
      state.pending = null;
    });
  },
});

export default orderSlice.reducer;
