import React from "react";

import { Trash2 } from "react-feather";

import Image from "../../../../../components/Image";

import {
  addToCart,
  decreaseCart,
  getTotals,
  removeFromCart,
} from "../../cart/cartSlice";

import { useDispatch, useSelector } from "react-redux";

function Review() {
  const cart = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  const handleRemoveFromCart = (cartItem) => {
    dispatch(removeFromCart(cartItem));
    dispatch(getTotals());
  };

  const handleDecreaseCart = (cartItem) => {
    dispatch(decreaseCart(cartItem));
    dispatch(getTotals());
  };

  const handleIncreaseCart = (cartItem) => {
    dispatch(addToCart(cartItem));
  };

  return (
    <div className="grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-16">
      {cart.cartItems?.map((cartItem) => (
        <div
          key={cartItem.id}
          className="flex flex-row items-center justify-between   text-secondary-200 pb-16"
        >
          {/* Image */}
          <div className="w-2/6 mr-2 ">
            <Image url={cartItem.imageUrl} />
          </div>

          {/* Desc and buttons */}
          <div className="flex flex-col justify-between lg:flex-col  xl:flex-row gap-5  w-4/6">
            {/* Desc */}
            <div>
              <p className=" text-sm font-bold">{cartItem.name}</p>
              <p className=" text-xs">Ghs {cartItem.price} per unit</p>
            </div>

            {/* Buttons */}

            <div className="gap-3 flex items-center">
              <button
                onClick={() => handleDecreaseCart(cartItem)}
                className=" bg-gray-50 hover:bg-primary-100 h-8 w-8 font-bold text-xl rounded-md"
              >
                -
              </button>
              <span className=" font-bold">{cartItem.cartQuantity}</span>
              <button
                onClick={() => handleIncreaseCart(cartItem)}
                className=" bg-gray-50 hover:bg-primary-100 h-8 w-8 font-bold text-xl rounded-md"
              >
                +
              </button>
              <button
                onClick={() => handleRemoveFromCart(cartItem)}
                className="flex justify-center hover:bg-red-200 items-center bg-gray-50 h-8 w-8 font-bold text-xl rounded-md"
              >
                <Trash2 className="h-5" />
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Review;

