/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export default function Pagination({
  dataPerPage,
  currentPage,
  setCurrentPage,
  totalData,
  paginate,
}) {
  return (
    <div>
      <button
        disabled={currentPage === 0}
        onClick={() => setCurrentPage(currentPage - 1)}
        className="bg-primary-200 disabled:bg-primary-100 disabled:cursor-not-allowed hover:bg-primary-400 cursor-pointer inline-block px-2 py-2  text-sm mx-1   text-white rounded-lg"
      >
        <p className="text-center mx-auto">Back</p>
      </button>

      <button
        onClick={() => setCurrentPage(currentPage + 1)}
        className="bg-primary-200 hover:bg-primary-400 cursor-pointer inline-block px-2 py-2  text-sm mx-1   text-white rounded-lg"
      >
        <p className="text-center mx-auto">Next</p>
      </button>
    </div>
  );
}

{
  /* <ul className="inline pagination">
        {pageNumbers.map((number) => (
          <li
            key={number}
            onClick={() => paginate(number)}
            className={`${
              currentPage === number
                ? "bg-yellow-500 hover:bg-yellow-800"
                : "bg-green-400 hover:bg-green-800"
            } cursor-pointer inline-block px-2 py-2 w-7  text-xs mx-1   text-white rounded-lg`}
          >
            <p className="text-center mx-auto">{number}</p>
          </li>
        ))}
      </ul> */
}
