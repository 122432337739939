import React from "react";

function Header({
  previousSteps,
  order,
  loading,
  setLoading,
  nextSteps,
  steps,
}) {
  return (
    <div>
      {/* Dont show header on last step */}
      {steps !== "last" && (
        <div className=" flex justify-between items-center pb-12 pt-6">
          <div className="flex gap-2 items-center">
            <h4 className=" font-bold text-xl">Your Venda Box</h4>
          </div>
          <div className="">
            {steps !== "first" && (
              <button
                onClick={() => previousSteps()}
                className="mr-4  border border-secondary-200 py-2 px-6 rounded-md text-secondary-200 text-xs"
              >
                Go Back
              </button>
            )}

            <button
              disabled={
                (steps === "second" && order.paymentMethod === null) || loading
              }
              onClick={() => nextSteps()}
              className=" bg-primary-200 disabled:bg-gray-300 disabled:cursor-not-allowed py-2 px-6 rounded-md text-white text-xs"
            >
              {loading ? (
                <span>Please wait...</span>
              ) : (
                <>
                  {steps === "first"
                    ? " Next: Payment & Delivery"
                    : steps === "second"
                    ? "Continue"
                    : steps === "third"
                    ? "Confirm Order"
                    : null}
                </>
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
