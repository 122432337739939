import React, { useEffect } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

// app pages import
import Landing from "./app/landing/Landing";
import ErrorPage from "./pages/404/ErrorPage";
import SignIn from "./pages/auth/signIn/SignIn";
import SignUp from "./pages/auth/signUp/SignUp";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import Business from "./pages/shop/business/Business";
import Verification from "./pages/auth/otpVerification/Verification";
import ConfirmEmail from "./pages/auth/forgetPassword/ConfirmEmail";
import PrivateRoutes from "./utils/PrivateRoute";
import Dashboard from "./pages/shop/business/Dashboard";
import Shop from "./pages/shop/business/Shop";
import Branches from "./pages/shop/business/branches/Branches";
import Billing from "./pages/shop/business/billing/Billing";
import Settings from "./pages/shop/business/Settings";
import Orders from "./pages/shop/business/orders/Orders";
import OrdersTable from "./pages/shop/business/orders/OrdersTable";
import OrderDetails from "./pages/shop/business/orders/OrderDetails";
import VendaBox from "./pages/shop/business/orders/VendaBox";
import VendaBoxIndex from "./pages/shop/business/orders/VendaBoxIndex";
import AllProducts from "./pages/shop/business/products/AllProducts";
import TermsOfService from "./pages/Terms/TermsOfService";
import PrivacyPolicy from "./pages/Terms/PrivacyPolicy";
import ForwardToAdmin from "./app/admin/ForwardToAdmin";

const contextClass = {
  success: "bg-white text-secondary-200",
  error: "bg-white text-secondary-200",
  info: "bg-white text-secondary-200",
  warning: "bg-orange-400",
  default: "bg-indigo-600",
  dark: "bg-white-600 font-gray-300",
};

function App() {
  // fake authentication Promise
  const authenticate = () => {
    return new Promise((resolve) => setTimeout(resolve, 1000)); // 2 seconds
  };

  useEffect(() => {
    authenticate().then(() => {
      const ele = document.getElementById("ipl-progress-indicator");
      if (ele) {
        // fade out
        ele.classList.add("available");
        setTimeout(() => {
          ele.outerHTML = "";
        }, 2000);
      }
    });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* admin forwarding */}
        <Route
          path="/auth/validate/admin/:id/:id"
          exact
          element={<ForwardToAdmin />}
        />
        {/* LANDING */}
        <Route path="/" exact element={<SignIn />} />

        <Route path="/login" exact element={<SignIn />} />
        <Route path="/signup" exact element={<SignUp />} />
        <Route path="/terms-of-service" exact element={<TermsOfService />} />
        <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
        <Route
          path="/auth/validate/:email/:magicLink"
          exact
          element={<Verification />}
        />

        <Route path="/confirm-email" exact element={<ConfirmEmail />} />
        <Route element={<PrivateRoutes />}>
          <Route path="business" exact element={<Business />}>
            <Route path="dashboard" exact element={<Dashboard />} />
            <Route path="shop" exact element={<Shop />}>
              <Route index element={<AllProducts />} />
              <Route path="products" element={<AllProducts />} />
            </Route>
            <Route path="orders" exact element={<Orders />}>
              <Route index element={<OrdersTable />} />
              <Route path="details/:orderID" element={<OrderDetails />} />
              <Route path="venda-box" element={<VendaBox />}>
                <Route index element={<VendaBoxIndex />} />
              </Route>
            </Route>
            <Route path="billing" exact element={<Billing />} />
            <Route path="branches" exact element={<Branches />} />
            <Route path="settings" exact element={<Settings />} />
          </Route>
        </Route>
        {/* PAGE NOT FOUND */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <ToastContainer
        toastClassName={({ type }) =>
          contextClass[type || "default"] +
          "  relative flex p-2 min-h-10 border border-gray-200  bg-white rounded-md justify-between overflow-hidden cursor-pointer"
        }
        bodyClassName={() => "text-sm font-black font-normal  block flex p-2"}
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </BrowserRouter>
  );
}

export default App;
