import { Plus } from "react-feather";
import { useDispatch } from "react-redux";
import { addToCart, getTotals } from "../pages/shop/business/cart/cartSlice";

const PlusButton = ({ product }) => {
  const dispatch = useDispatch();
  const handleAddToCart = (product) => {
    dispatch(addToCart({ ...product, quantity: 1 }));
    dispatch(getTotals());
  };

  return (
    <div
      onClick={() => handleAddToCart(product)}
      className="rounded-full bg-yellow-100 place-self-start"
    >
      <button className="flex items-center justify-center bg-primary-200 hover:bg-primary-400 h-8 w-8 shadow-primary-200 hover:shadow-primary-400 shadow-md rounded-full">
        <span className=" font-thin text-white text-h4 items-center">
          <Plus className="h-8 w-4 flex-shrink-0" aria-hidden="true" />
        </span>
      </button>
    </div>
  );
};

export default PlusButton;
