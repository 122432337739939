import heroImage from "../../../assets/images/signup.png";
import { NavLink, useNavigate } from "react-router-dom";

import { handleRecaptchaSubmission } from "../../../utils/captcha";

import { useEffect, useState } from "react";

// import AuthFooter from "../../../components/AuthFooter";

import { Mail, MapPin, User, Sidebar, Phone } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../userSlice";
import { useFormik } from "formik";
import { registerSchema } from "../../../schemas";
import { toast } from "react-toastify";
import Logo from "../../../assets/images/logo-dark.svg";

import Modal from "../forgetPassword/Modal";

const SignUp = () => {
  const dispatch = useDispatch();
  const { pending } = useSelector((state) => state.user);

  // CAPTCHA Implementation
  const [loading, setLoading] = useState(false);
  const [allowAuth, setAllowAuth] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // state

  const [businessName, setbusinessName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [location, setLocation] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const formInputStyle = `border-b w-full bg-[#f4f4f4] border-secondary-200 text-sm px-8 py-2`;

  // const {
  //   values,
  //   errors,
  //   handleChange,
  //   handleSubmit,
  //   handleBlur,
  //   touched,
  //   isValid,
  // } = useFormik({
  //   initialValues: {
  //     businessName: "",
  //     firstName: "",
  //     lastName: "",
  //     location: "",
  //     email: "",
  //     phoneNumber: "",
  //   },
  //   validateOnMount: true,
  //   validationSchema: registerSchema,
  //   onSubmit: async (values, actions) => {
  //     try {
  //       const res = await dispatch(registerUser({ ...values })).unwrap();
  //       actions.resetForm();
  //       navigate("/confirm-email");
  //       toast.success(`${res.message}`);
  //     } catch (error) {
  //       if (error.message === "Rejected") {
  //         toast.error(`Network error`);
  //       } else if (error.message === "Duplicate keys") {
  //         toast.error(`Business already exists`);
  //       } else {
  //         toast.error(`${error.message}`);
  //       }
  //     }
  //   },
  // });

  // handle sign in using data and captcha
  useEffect(() => {
    let signIn = async () => {
      try {
        const res = await dispatch(
          registerUser({
            businessName,
            location,
            firstName,
            lastName,
            email,
            phoneNumber,
          })
        ).unwrap();

        setLoading(false);
        setShowModal(true);
        setAllowAuth(false);
        // toast.success(`${res.message}`);

        // END OF TRY
      } catch (error) {
        if (error.message === "Rejected") {
          setLoading(false);
          setAllowAuth(false);
          toast.error(`Error occured. Please refresh and try again`);
        } else {
          setLoading(false);
          setAllowAuth(false);
          toast.error(`${error.message}`);
        }
      }
    };

    if (allowAuth) {
      signIn();
    }
  }, [allowAuth]);

  // initialize recaptcha
  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_SITE_KEY}`,
      function () {
        // console.log("Script loaded!");
      }
    );
  }, []);

  return (
    <div>
      <Modal
        type="create"
        email={email}
        setEmail={setEmail}
        isOpen={showModal}
        setIsOpen={setShowModal}
      />
      <div className="mx-auto h-full">
        <div className="flex h-fit">
          <div className="w-full lg:w-1/2 mx-auto px-8 relative">
            <div className="lg:w-8/12 w-full mx-auto pt-16">
              <NavLink to="/">
                <img className="w-20" src={Logo} alt="logo" />
              </NavLink>
              <div className="text-secondary-200 flex h-full py-16">
                <div>
                  {" "}
                  <h4 className=" text-xl lg:text-h4 font-bold">
                    Create your Business Account
                  </h4>
                  <p className=" text-sm lg:text-md">
                    Already have an account?{" "}
                    <NavLink to="/login">
                      <span className=" text-primary-200 ">Sign In</span>
                    </NavLink>
                  </p>
                  <form
                    onSubmit={(e) =>
                      handleRecaptchaSubmission(e, setLoading, setAllowAuth)
                    }
                  >
                    <div className="mt-4">
                      {/* BUSINESS NAME */}
                      <div className="flex relative">
                        <Sidebar
                          className="absolute font-medium inset-0 h-9 w-5"
                          aria-hidden="true"
                        />
                        <input
                          type="text"
                          name="businessName"
                          value={businessName}
                          onChange={(e) => setbusinessName(e.target.value)}
                          className={`${formInputStyle}`}
                          placeholder="Business / Company Name"
                        />
                      </div>
                      {/* LOCATION */}
                      <div className="flex relative mt-4">
                        <MapPin
                          className="absolute font-medium  inset-0 h-9 w-5"
                          aria-hidden="true"
                        />
                        <input
                          type="text"
                          name="location"
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                          className={`${formInputStyle}`}
                          placeholder="Location of business"
                        />
                      </div>
                      {/* FIRST NAME */}
                      <div className="flex relative mt-4">
                        <User
                          className="absolute font-medium  inset-0 h-9 w-5"
                          aria-hidden="true"
                        />
                        <input
                          type="text"
                          name="firstName"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          className={`${formInputStyle}`}
                          placeholder="First Name"
                        />
                      </div>{" "}
                      {/* LAST NAME */}
                      <div className="flex relative mt-4">
                        <User
                          className="absolute font-medium  inset-0 h-9 w-5"
                          aria-hidden="true"
                        />
                        <input
                          type="text"
                          name="lastName"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          className={`${formInputStyle}`}
                          placeholder="Last Name"
                        />
                      </div>
                      {/* EMAIL ADDRESS */}
                      <div className="flex relative mt-4">
                        <Mail
                          className="absolute font-medium  inset-0 h-9 w-5"
                          aria-hidden="true"
                        />
                        <input
                          type="email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className={`${formInputStyle}`}
                          placeholder="Email Address"
                        />
                      </div>
                      {/* PHONE NUMBER */}
                      <div className="flex relative mt-4">
                        <Phone
                          className="absolute font-medium  inset-0 h-9 w-5"
                          aria-hidden="true"
                        />
                        <input
                          type="phoneNumber"
                          name="phoneNumber"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          className={`${formInputStyle}`}
                          placeholder="Phone Number"
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      disabled={
                        loading ||
                        pending ||
                        firstName.length < 2 ||
                        lastName.length < 2 ||
                        location.length < 2 ||
                        !email.includes("@") ||
                        phoneNumber.length < 5
                      }
                      className="btn-primary-full w-full mt-6 mb-7 flex place-content-center disabled:opacity-75 disabled:cursor-not-allowed disabled:hover:none disabled:transition-none shadow-lg enabled:hover:bg-primary-400  transition-all"
                    >
                      {pending || loading ? (
                        <div role="status">
                          <svg
                            aria-hidden="true"
                            className="w-6 h-6 mr-2 text-center text-gray-200 animate-spin dark:text-gray-600 fill-white"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        </div>
                      ) : (
                        <> Create Business Account</>
                      )}
                    </button>
                  </form>
                  <p className=" text-xs pb-4">
                    By creating an account, I agree to Venda's <br />
                    <NavLink to="/terms-of-service">
                      <span className=" text-primary-200">
                        Terms of Service
                      </span>{" "}
                    </NavLink>
                    and acknowledge the{" "}
                    <NavLink to="/privacy-policy">
                      <span className=" text-primary-200"> Privacy Policy</span>
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
            {/* <AuthFooter /> */}
          </div>
          <div className="hidden md:inline w-1/2">
            <div className="h-full w-full bg-primary-400">
              <img
                className="w-full h-screen bg-primary-400 object-cover"
                src={heroImage}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
