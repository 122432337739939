import React, { useEffect } from "react";
import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { NavLink, useNavigate, Outlet } from "react-router-dom";
import Logo from "../../../assets/images/logo.svg";

import {
  ShoppingBagIcon,
  BuildingStorefrontIcon,
} from "@heroicons/react/24/outline";

import { BarChart2, Settings, LogOut, ChevronDown, X } from "react-feather";
import { Menu as M } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../auth/userSlice";

const navigation = [
  {
    name: "Dashboard",
    href: "dashboard",
    icon: BarChart2,
  },
  { name: "Shop", href: "shop/products", icon: BuildingStorefrontIcon },
  {
    name: "Your Orders",
    href: "orders",
    icon: ShoppingBagIcon,
  },

  { name: "Settings", href: "settings", icon: Settings },
];
const isComplete = true;
const Business = () => {
  const navigate = useNavigate();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [navName, setNavName] = useState("Dashboard");

  const { cartTotalQuantity } = useSelector((state) => state.cart);

  const [merge, setMerge] = useState("");

  // get customer initials
  useEffect(() => {
    //
    let customer = JSON.parse(localStorage.getItem("user"));
    let str = customer.businessName;
    let first = str.charAt(str.length[0]).toUpperCase();
    let last = str.charAt(str.length - 1).toUpperCase();
    setMerge(first + last);
  }, []);

  return (
    <>
      <div className="min-h-screen bg-[#F4F4F4]">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-secondary-200 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-secondary-200 pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0  z-10 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <X
                          className="text-white h-6 w-6 flex-shrink-0 text-white-200"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Close sidebar</span>
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <p className=" text-white font-bold text-h4">Venda</p>
                  </div>
                  <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          className={({ isActive }) =>
                            isActive
                              ? " transition-all bg-secondary-100 text-primary-100 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-xl"
                              : "group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-xl text-white hover:text-white hover:bg-secondary-400"
                          }
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-white-200"
                            aria-hidden="true"
                          />
                          <span className=" px-6">{item.name}</span>
                        </NavLink>
                      ))}
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          {/* Sidebar component*/}
          <div className="flex flex-grow flex-col overflow-y-auto bg-secondary-200 pt-5 pb-4">
            <div className="flex flex-shrink-0 items-center pl-12 pb-2  w-full  border-b-[0.5px]">
              <img className="my-4 w-20" src={Logo} alt="logo" />
            </div>

            <nav
              className="mt-5 flex flex-1 flex-col divide-y divide-secondary-200 overflow-y-auto"
              aria-label="Sidebar"
            >
              <div className="space-y-3 px-4 pt-1">
                {navigation.map((item) => (
                  <NavLink
                    onClick={() => setNavName(item.name)}
                    key={item.name}
                    to={item.href}
                    className={({ isActive }) =>
                      isActive
                        ? " transition-all bg-secondary-100 text-primary-100 group flex items-center px-8 py-2 text-sm leading-6 font-medium rounded-xl"
                        : "  group flex items-center px-8 py-2 text-sm leading-6 font-medium rounded-xl text-white hover:text-white hover:bg-secondary-400"
                    }
                    aria-current={item.current ? "page" : undefined}
                  >
                    <item.icon
                      className=" h-6 w-6 flex-shrink-0 text-white-200"
                      aria-hidden="true"
                    />
                    <span className=" px-6">{item.name}</span>
                  </NavLink>
                ))}
              </div>
            </nav>
            <div className="text-center  text-primary-100">
              <div
                className=" flex justify-start ml-12 items-center"
                onClick={() => {
                  navigate("/login");
                  localStorage.removeItem("token");
                }}
              >
                <LogOut
                  className=" font-medium cursor-pointer h-9 w-5 mr-3 "
                  aria-hidden="true"
                />
                Log Out
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-1 flex-col lg:pl-64 ">
          <div className="flex py-2 items-center justify-between  w-full  bg-neutral-50 lg:border-none">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-400 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <M className=" h-6 w-6 " aria-hidden="true" />
            </button>
            <div className=" flex items-center text-xl font-bold lg:mx-12 text-secondary-200">
              {navName}
            </div>

            <div className="flex  px-4 justify-between  h-12 items-center  sm:px-6  lg:max-w-2xl lg:px-8">
              {/* Search bar */}
              {/* <div className="flex flex-1 border-black border px-4 bg-white rounded-lg">
                <label htmlFor="search-field" className="sr-only ">
                  Search
                </label>
                <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                  <div
                    className="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                    aria-hidden="true"
                  >
                    <Search className="h-5 w-5" aria-hidden="true" />
                  </div>

                  <div
                    className="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                    aria-hidden="true"
                  ></div>
                  <input
                    id="search-field"
                    name="search-field"
                    className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                    placeholder="Search products..."
                    type="search"
                  />
                </div>
              </div> */}

              {/* Cart Summary */}
              <div className="ml-4 flex items-center md:ml-6">
                {/* <button
                  type="button"
                  className="rounded-full mr-3 relative  p-1 text-secondary-200 hover:text-gray-500 "
                >
                  <span className="sr-only">View notifications</span>
                  <Bell className="h-6 w-6" aria-hidden="true" />
                  <div class="inline-flex absolute -top-0 -right-0 justify-center items-center w-2 h-2 text-[9px]  text-white bg-[#F33E3E] rounded-full"></div>
                </button> */}

                <button
                  onClick={() => navigate("orders/venda-box")}
                  type="button"
                  className="rounded-full inline-flex relative  p-1 text-secondary-200 hover:text-gray-500 "
                >
                  <span className="sr-only">View Cart</span>
                  <ShoppingBagIcon className="h-6 w-7" aria-hidden="true" />
                  <div class="inline-flex absolute -top-1 -right-2 justify-center items-center w-4 h-4 text-[9px] font-bold text-white bg-success-200 rounded-full">
                    {cartTotalQuantity}
                  </div>
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center rounded-full  text-sm focus:outline-none   lg:rounded-md lg:p-2 ">
                      <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-primary-100">
                        <span className="text-sm font-bold leading-none ">
                          {/* fetch authenticated users data from endpoint */}
                          {merge}
                        </span>
                      </span>
                      <ChevronDown
                        className="ml-2 hidden h-4 w-5 flex-shrink-0 text-gray-400 lg:block"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="z-50 absolute right-0  mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        <NavLink
                          to="#"
                          className=" block px-4 py-2 text-sm text-gray-700"
                        >
                          Your Profile
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item>
                        <NavLink
                          to="#"
                          className=" block px-4 py-2 text-sm text-gray-700"
                        >
                          Settings
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item>
                        <div
                          onClick={() => {
                            navigate("/login");
                            localStorage.removeItem("token");
                          }}
                          className="block px-4 py-2 text-sm text-gray-700"
                        >
                          Logout
                        </div>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          {/* Page header */}
          <main className="flex-1 pb-8 w-11/12 mx-auto bg-[#F4F4F4]">
            <div className="pt-8">
              {/* {!isComplete && (
                <div className=" w-full bg-error-200 text-white text-sm flex py-3 mb-6 justify-between px-12 items-center rounded-[8px]">
                  <div className=" flex items-center">
                    <div className="bg-error-100 h-12 w-12 rounded-full flex justify-center items-center ">
                      3/5
                    </div>
                    <p className="pl-4">
                      Complete your business profile <br /> to start placing
                      orders
                    </p>
                  </div>
                  <div>
                    <button className="bg-error-100 py-2 px-12 rounded-lg text-sm">
                      Go to Profile
                    </button>
                  </div>
                </div>
              )} */}

              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Business;
